nav {
    width: 100%;
    height: 45px;
    position: relative;
    display: grid;
    grid-template-columns: 20% 20% 20% 20% 20%;
    grid-template-rows: 1fr;
    justify-content: center;
    align-items: center;
    z-index: 6;
    overflow: visible;
    background-color: black;
    border-bottom: 1px solid hsla(72, 100%, 47%, 60%);
    transition: all 0.2s;
    -webkit-transition: all 0.2s;
    
}

.encRemAdsCont {
    width: 100%;
    justify-content: center;
    display: flex;
    position: absolute;
    margin: 0px auto;

}

.encRemAds {
    color: white;
    display: flex;
    width: fit-content;
    gap: 5px;
}

.encRemAds img {
    height: 30px;
}



.chatNotibutton img {
    width: 25px;
}

.infoTabList {
    grid-column-start: 2;
    grid-column-end: 6;
    display: flex;
    z-index: 50;
}

.infoNotibutton {
    position: fixed;
    top: 12px;
    left: 57px;
}

.infoNotibutton img {
    width: 24px;
}

.infoList {
    background-color: rgb(228, 228, 228);
    position: fixed;
    border-radius: 10px;
    max-height: 500px;
    width: 130px;
    top: 40px;
    left: 70px;
    overflow-y: scroll;
    z-index: 50;
}

.smallInfoCard {
    justify-content: center;
    display: grid;
    grid-template-columns: 100%;
    justify-content: center;
    border-radius: 10px;
    padding: 3px;
    margin: 3px;
    background-color: white;
    text-decoration: none;
    color: #2a4260;
}

.homeIMG {
    top: 0px;
    position: fixed;
    height: 45px;
    width: 45px;
    z-index: 10;
}


.DropDown {
    top: 0;
    left: 48px;
    position: fixed;
    height: 45px;
    width: 45px;
    z-index: 10;
}

.HomeDropDownCont {
    background-color: hsla(0, 0%, 15%, 0.98);
    position: fixed;
    justify-content: left;
    top: 44px;
    left: 0px;
    padding-top: 10px;
    text-align: left;
    z-index: 50;
    border-top: 1px solid hsla(72, 100%, 47%, 65%);
    border-right: 1px solid hsla(72, 100%, 47%, 65%);
    border-left: 0px;
    transition: .15s;
    height: calc(100% - 44px)
}

.mainBackArrow {
    position: fixed;
    width: 50px;
    height: 45px;
    top: 0px;
    padding: 13px 14px;
    border-bottom-right-radius: 10px;
    opacity: 0.7;


}

.fakeline {
    height: 2px;
    width: 75%;
    background-color: hsla(72, 100%, 47%, 65%);
}

.singleLink {
    display: flex;
    align-items: center;
    text-align: left;
    color: hsl(72, 100%, 47%);
    line-height: 2.1;
    font-weight: 500;
    letter-spacing: 1px;
    padding: 0px 20px;
}

.singleLink div p {
    text-align: left;
    margin-left: 0px;
    margin-right: 0px;

}

.singleLink div img {
    margin-left: 10px;

}

.dropDpowSmallButtons {
    margin-top: 15px;
    display: flex;
    width: fit-content;
    gap: 15px;
    margin-left: 0px;
}

.enableN {
    display: flex;
    position: relative;
    z-index: 3;
    background-color: rgba(255, 0, 0, 0.274);
    border-radius: 10px;
    margin-left: 10px;
    height: 30px;
    width: 30px;

}

.enableN img {
    height: 60%;
}

.shareImageInDrop {
    height: 25px;
}


/*theme colours

dark blue:  #2a4260
mid blue:  #28afe6
mid blue:  #28afe6
background: linear-gradient(to top, #11345f, #288195, white);


*/