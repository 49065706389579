.appServicesCont {
    background-color: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    /* Adjust as needed */
    gap: 10px;
    padding: 0px 10px;
    margin: 10px auto;
    position: relative;
    width: 100%;
}

.appServiceButton {
    background-color: hsla(0, 0%, 13%, 1);
    flex: 0 0 calc(25% - 10px);
    max-width: calc(25% - 10px);
    border-radius: 15px;
    /* 33.33% width for each item with a 10px gap */
    aspect-ratio: 1;
    overflow: hidden;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
}

.editImgAppServ {
    width: 40px;
    padding: 12px;
    position: absolute;
    top: -45px;
    right: 15px;
}

.descContPage {
    width: 100%;
    position: fixed;
    height: 100vh;
    top: 0px;
    left: 0px;
    z-index: 500;
    background-color: rgba(0, 0, 0, 0.143);
}

.descCont {
    border-radius: 15px;
    padding: 10px;
    border: 1px solid rgba(255, 255, 255, 0.488);
    position: fixed;
    top: 30%;
    left: 10px;
    z-index: 50;
    width: calc(100% - 20px);
    height: auto;
    background-color: hsla(0, 0%, 12%, 0.973);
    display: flex;
    flex-direction: column;
}

.appServicesCont h3 {
    width: 100%;
    padding: 10px;
}



.appServiceButtonLogoCont {
    padding-top: 5px;
    height: fit-content;
    height: 80%;
    width: 100%;
}

.appServiceButtonLogo {
    height: 45%;
    width: auto;
}

.appServiceButtonInfoCont {
    width: 100%;
    gap: 5px;
    line-height: 1.2;
    align-items: center;
    height: 30%;

}

.appServiceButtonInfoImg {
    position: relative;    
    width: 20%;
    padding: 3%;
    justify-self: right;
    margin-bottom: 3%; 
    margin-right: 5%;  
}

.appServiceOrderEdit {
    position: absolute;    
    width: 20%;    
    justify-self: right;
    top:3px ;
    left:3px;
    color: hsl(72, 100%, 47%);
}

.appServiceButton p {
    color: white;
    text-align: left;
    font-size: 12px;
}

.appServiceButton h5 {
    margin-top: 2px;
    color: rgba(255, 255, 255, 0.689);
    font-size: 8px;
}

.showMoreAppServices {
    background-color: hsla(0, 0%, 13%, 1);
    width: calc(100% - 20px);
    height: 30px;
    align-items: center;
    display: flex;
    justify-content: center;    
    border-radius: 15px;
    margin: 10px auto;

}