.mainProfile {
  position: fixed;
  top: 45px;
  width: 100%;

}

.profileCont {
  position: relative;
  justify-content: center;
  display: grid;
  grid-template-columns: repeat(4, 25%);
  /*
  grid-template-rows: 10% 35% 15% 15% 15% 10%;
  */
  grid-template-rows: 11% 40% 13% 13% 13% 10%;
  color: whitesmoke;
  z-index: 20;
  overflow: visible;
  padding: 12px 12px 0px 12px;
  height: calc(100vh - 144px);
  width: calc(100% - 36px);
  gap: 12px;
}

.workOutButotn {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 3;
  grid-row-end: 5;
  width: 100%;
  border-radius: 15px;
  background-color: hsla(72, 100%, 47%, 65%);
  text-decoration: none;
  height: 100%;
  color: black;
  margin: auto;
  font-size: 25px;
  font-weight: 900;
  font-style: italic;
  line-height: 1.5;
}


.workOutButotn:active {
  background-color: hsla(0, 0%, 25%, 80%);
  color: white;
}


.revisionC {
  width: 100%;
  position: relative;
  color: black;
  margin: auto;
  font-size: 22px;
  font-weight: 900;
  font-style: italic;
}

.infoCont {
  grid-column-start: 1;
  grid-column-end: 5;
  grid-row-start: 2;
  grid-row-end: 3;
  display: grid;
  grid-template-columns: min-content 1fr;
  grid-template-rows: 70% 30%;
  background-color: hsla(0, 0%, 25%, 80%);
  width: 100%;
  height: 100%;
  border-radius: 15px;
}

.favsCont {
  display: flex;
  grid-column-start: 1;
  grid-column-end: 5;
  grid-row-start: 2;
  grid-row-end: 3;
  height: 30%;
  width: calc(100% - 20px);
  margin-bottom: 0px;
}

.setFavsGetNotified {
  align-items: center;
  background-color: #f5f5f59c;
  height: 50px;
  display: flex;
  border-radius: 15px;
  width: 80px;
  margin-bottom: 10px;
}

.favs {
  height: 100%;
  right: 10px;
  display: flex;
  margin-right: 0px;
  gap: 6px;
  width: fit-content;
}

.fav1 {
  background-color: #f5f5f59c;
  height: 50px;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  width: 50px;
  margin-bottom: 10px;
}

.fav1:active {
  background-color: hsla(72, 100%, 47%, 65%);
  color: white;
}

.fav1 img {
  position: relative;
  filter: grayscale(1);
  width: 35px;
}

.dropdownContentContainer {
  position: fixed;
  height: calc(100%);
  top: 0px;
  width: 100%;
  left: 0;
  display: flex;
  z-index: 600;

}

.dropdownContent {
  width: fit-content;
  padding: 5px 10px 0px 10px;
  background-color: hsla(0, 0%, 25%, 0.951);
  color: black;
  z-index: 5;
  line-height: 1;
  border-radius: 10px;
  border: 2px solid hsla(72, 100%, 47%, 65%);
}

.favsButtons {
  margin-top: 10px;
  height: 100%;
  display: grid;
  gap: 10px;
  row-gap: 10px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
}

.favsButtons div {
  padding: 10px 15px;
  border-radius: 15px;
  background-color: #f5f5f5e0;
  aspect-ratio: 1;
  width: 100%;
  justify-content: center;
  align-self: center;
}

.favsButtons div:active {
  filter: invert(1);
}

.dropdownItem {
  margin: 12px 0px;
}

.removeFav {
  color: black;
  background-color: white;
  width: fit-content;
  margin: 12px auto;
  padding: 5px 20px;
  border-radius: 10px;
}

.ProfilePic {
  position: relative;
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;
  height: 85%;
  aspect-ratio: 1;
  margin-top: 10px;
  margin-left: 10px;
  border-radius: 10px;
  animation: gradientAnimationBackGround 2s infinite linear;
}

.ProfilePic img {
  position: relative;
  height: 100%;
  border-radius: 10px;
}


.profileText {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 2;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-top: 25px;

}


.fullName {
  margin-left: 0px;
  position: relative;
  padding-right: 10px;
  font-size: 18px;
  font-weight: 600;
  text-align: left;
  margin-bottom: 5px;
}

.gender {
  margin-left: 0px;
  margin-right: 5px;
  position: relative;
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 2;
  font-style: italic;
  font-weight: 600;
  font-size: 13px;
}

.age {
  font-style: italic;
  font-weight: 600;
  font-size: 13px;
}


.clock {
  position: relative;
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 2;
  margin-top: 10px;
  margin-right: 10px;
  letter-spacing: 0px;
  font-size: 12px;
  font-weight: 500;
}

.bioCont {
  display: flex;
  margin-left: 0px;
  font-style: italic;
}

.picandnot {
  display: flex;
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 2;
  grid-row-end: 3;
  height: 100%;
  width: auto;
  margin-left: 10px;
  padding-bottom: 10px;
  gap: 10px;
}

.publicStatusCont {
  display: flex;
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 2;
  grid-row-end: 3;
  height: 100%;
  width: auto;
  margin-left: 10px;
  padding-bottom: 10px;
  gap: 10px;
}

.publicStatusCont img {
  height: 100%;
  z-index: 20;
}


.changePPCont {
  height: 100%;
  width: 50%;
  display: flex;
  border-radius: 15px;
  background-color: #f5f5f59c;
  font-size: 14px;
  z-index: 20;

}

.changePPCont:active {
  filter: invert(1);
}


.oneUserPhoto {
  width: 90%;
  filter: invert(.25);
}

.feedButtonPhoto {
  width: 75%;
  filter: grayscale(1);
}

.PPIMG {
  display: flex;
  height: 50%;
  width: auto;
}


.borderImg {
  position: relative;
  margin-top: 6px;
  bottom: 12px;
  height: 126px;
  grid-row-start: 1;
  grid-row-end: 4;
  grid-column-start: 1;
  grid-column-end: 5;
  z-index: 0;
}



.welcome {
  text-align: left;
  width: 320px;
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 2;
  font-size: 20px;
  font-weight: 800;
  font-style: italic;
  line-height: 1.2;
  letter-spacing: 1px;
}

.confirmedwithtime {
  background-color: hsla(0, 0%, 25%, 0.951);
  position: relative;
  grid-column-start: 1;
  grid-column-end: 5;
  width: 100%;
  padding: 7px 0px;
  border-radius: 15px;
}

.profileTimeview p {
  text-align: center;
}

.connectsbtn {
  padding: 5px;
  grid-column-start: 3;
  grid-column-end: 5;
  grid-row-start: 3;
  grid-row-end: 4;
  height: 100%;
  width: 100%;
  border-radius: 15px;
  display: grid;
  grid-template-columns: 30% 70%;
  z-index: 2;
  background-color: #f5f5f59c;
  text-decoration: none !important;
  user-select: none;
}

.connectsbtn div {
  display: flex;
  border-radius: 10px;
  align-items: center;
  background-color: hsla(0, 0%, 25%, 0.8);
  height: 100%;
  width: 100%;

}

.connectsbtn img {
  filter: invert(1);
  width: 30px;
}

.connectsbtn p {
  color: black;
  margin: auto;
  font-size: 20px;
  font-weight: 800;
  font-style: italic;
}

.connectsbtn:active {
  filter: invert(1);
}


.profInfo h3 {
  margin-left: 0;
}

.profInfo p {
  margin-left: 0;
}

.editButton {
  width: 18px;
  position: relative;
}

.editButton:active {
  filter: invert(1);
}

.visbToCont {
  display: flex;
  grid-column-start: 3;
  grid-column-end: 5;
  grid-row-start: 4;
  grid-row-end: 6;
  width: 100%;
  height: 100%;
  border-radius: 15px;
  background-color: #f5f5f59c;
}

.showToCont {
  grid-template-rows: 60% 40%;
  grid-template-columns: 100%;
  align-items: center;
  display: grid;
  height: 100%;
  justify-content: center;
  width: 100%;
  border-radius: 4px;
}



.visbToCont p {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;
  width: 100%;
  position: relative;
  color: black;
  margin: auto;
  font-size: 25px;
  font-weight: 900;
  font-style: italic;

}

.showToCont div {
  grid-column-start: 1;
  grid-column-end: 2;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  padding: 0px 5px 5px 5px;
}

.showToCont button {
  width: 30%;
  height: 90%;
  display: flex;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  background-color: hsla(0, 0%, 25%, 0.8);
  border: 0px solid;

}

.showToCont img {
  height: 80%;
}

.showToCont h2 {
  color: white;
  margin: auto;
  font-size: 18px;
  font-weight: 800;
  font-style: italic;
}

.openList {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 5;
  grid-row-end: 6;
  display: grid;
  background-color: hsla(0, 0%, 25%, 80%);
  width: 100%;
  height: 100%;
  border-radius: 15px;
}

.openListGrid {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 70% 30%;
}

.openListImgCont {
  background-color: #f5f5f5bc;
  height: calc(100% - 10px);
  display: flex;
  width: calc(100% - 5px);
  margin-right: 5px;
  border-radius: 10px;
}

.openList p {
  font-weight: 600;
  font-size: 20px;
}

.openListGrid:active {
  filter: invert(1);
}

.imageLinkChanger {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 5;
  grid-row-end: 6;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 100%;
  background-color: hsla(0, 0%, 25%, 80%);
  width: 100%;
  height: 100%;
  border-radius: 15px;

}

.imageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 10px;

}

.imageContainer:active {
  background-color: black;
  border-radius: 15px;
}


.solciallinkimg {
  border-radius: 15px;
  height: 100%;
  max-width: 100%
}


.fakeProfilePic {
  position: relative;
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;
  height: 85%;
  aspect-ratio: 1;
  width: calc(100% - 10px);
  margin-top: 10px;
  margin-left: 10px;
  border-radius: 10px;
  animation: gradientAnimationBackGround 2s infinite linear;
}

@keyframes gradientAnimationBackGround {
  0% {
    background-color: hsl(0, 0%, 15%);
  }

  50% {
    background-color: rgb(162, 162, 162)
  }

  100% {
    background-color: hsl(0, 0%, 15%);
  }
}



