.chat-card {
    border-radius: 15px;
    padding: 5px;
    background-color: hsla(0, 0%, 25%, 80%);
    display: grid;
    grid-template-columns: 50px auto 30px;
    margin-bottom: 10px;
    height: 55px;

}

.user-details {
    text-align: left;
    margin-left: 0;
    line-height: 1.2;
    height: 100%;
    padding-top: 2px;
    overflow: hidden;
}


.smallText1 {
    margin-left: 10px;
    text-decoration: none;
    color: white;
    display: flex;
    grid-column-start: 2;
    grid-column-end: 3;
    width: 100%;
    font-size: 18px;
    font-weight: 500;
}

.smallText2 {
    margin-left: 10px;
    text-decoration: none;
    color: white;
    display: flex;
    grid-column-start: 2;
    grid-column-end: 3;
    width: 100%;
    height: 100%;
    overflow: hidden;
    font-weight: 400;
}

.user-picture {
    height: 45px;
    border-radius: 10px;
    margin: 0;
}