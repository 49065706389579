.ScrollEventsCont {
    width: 100%;
}

.slick-track {
    margin-bottom: 10px;
}

.slick-dots {
    position: absolute;
    bottom: -10px;
}

.slick-dots li button:before {
    color: rgb(255, 255, 255);
    /* Change this to the color you want */
}

/* Change color of active dot */
.slick-dots li.slick-active button:before {
    color: hsl(72, 100%, 47%);
    /* Change this to the color for active dots */
}

.slick-prev {
    left: 15px;
    z-index: 15;
    /* Adjust the left position as needed */
}

.slick-next {
    right: 15px;
    /* Adjust the right position as needed */
}

.eventCardHider {
    height: calc(100vw * 0.75);
    width: 100%;
    position: absolute;
    z-index: 10;
    user-select: none;
}

.eventCardCont h1 {
    color: rgb(220, 220, 220);
    font-size: 20px;
    margin-top: 20px;
}

.eventCardCont {
    background-color: hsla(0, 0%, 13%, 1);
    display: flex;
    flex-direction: column;
    padding-bottom: 5px;
    width: calc(100% - 20px);
    border-radius: 20px;
    height: 100%;
    margin-top: 10px;
    position: relative;
}

.eventCardCont img {
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
}

.eventTagCard {
    height: fit-content;
    width: fit-content;
    position: absolute;
    bottom: 28px;
    display: flex;
    right: 25px;
    padding: 0px 20px;
    justify-content: left;
}

.eventTagChild {
    display: flex;
    gap: 0px;
    width: fit-content;
    margin-left: 10px;
}

.eventTagChild img {
    height: 18px;

}

.eventTagChild h4 {
    margin-left: 5px;
    font-size: 14px;
    font-weight: normal;
}

.eventCardCont h5 {
    margin-left: 15px;
}

.eventCardCont h3 {
    color: white;
    font-size: 16px;
    margin: 7px 10px;
    width: 100%;
    text-align: left;
}


.eventCardCont p {
    width: 100%;
    height: 30px;
    overflow: hidden;
    font-family: sans-serif;
    font-weight: 300;
    color: white;
    margin: 0px 10px;
    text-align: left;
    padding-bottom: 10px;
}

.editEventInCard {
    width: 20px;
    position: absolute;
    top: calc(100vw * .56);
    right: 20px;
    border-radius: 0px;
}

.editEventInPage {
    width: 20px;
    right: 20px;
    border-radius: 0px;
    position: relative;
}

.eventPageMainImgContainer {
    position: relative;
    width: 100%;
    padding-top: 56.25%;
    /* 16:9 aspect ratio (16/9 = 0.5625 -> 56.25%) */
    overflow: hidden;
}

.eventPageMainImg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.eventPageTitle {
    font-size: 20px;
    width: 100%;
    color: rgb(230, 230, 230);
    margin-bottom: 0px;
}

.eventGoing {
    margin-top: 10px;

}


.eventPageBadge {
    width: calc(100%);
    color: rgb(230, 230, 230);
    font-weight: 500;
    padding: 8px 0px;
    position: relative;
}

.smallGreenButton {
    background-color: hsla(72, 100%, 47%, 80%);
    color: black;
    align-items: center;
    padding: 3px 15px;
    font-weight: 500;
    font-size: 16px;
    border-radius: 15px;

}

.goingEventCount {
    width: fit-content;
    height: 26px;
    display: flex;
    gap: 5px;
}

.goingEventCount img {
    width: 20px;
}

.imgAndTextEvent {
    padding: 5px 5px;
    display: grid;
    grid-template-columns: 10% 90%;
}

.imgAndTextEvent img {
    width: 60%;
}

.imgAndTextEvent p {
    font-size: 14px;
    margin-left: 5px;
    white-space: pre-wrap;
    text-align: left;
}

.overviewText {
    text-align: left;
    margin-left: 20px;
    font-size: 14px;
    padding-right: 10px;
    white-space: pre-wrap
}

.eventButtons {
    display: flex;
    margin-top: 10px;
}

.eventButtons p {
    background-color: hsla(0, 0%, 13%, 1);
    width: 30%;
    color: white;
    font-weight: 500;
    height: 50px;

}

.editableInput {
    font-size: inherit;
    font-family: inherit;
    color: inherit;
    background: inherit;
    border: none;
    border-bottom: 1px solid currentColor;
    padding: 0;
    margin: 0;
    outline: none;
    border-radius: 0px;
    width: 100%;
}

.phoneNumberEventEdit input {
    font-size: inherit;
    font-family: inherit;
    color: inherit;
    background: inherit;
    border: none;
    border-bottom: 1px solid currentColor;
    padding: 0;
    margin: 0;
    outline: none;
    border-radius: 0px;
    width: 100%;
}

.phoneNumberEventEdit p {
    width: 100%;
}