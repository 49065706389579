.containerLogin {
    margin-top: 45px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    top: 0px;
    left: 0px;
    height: calc(100vh - 45px);
}

.boxLogin {
    margin: auto auto;
    width: 90%;
    padding: 20px;
    background-color: #f5f5f59c;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    text-align: center;
    border: 1px solid #2a426049;
}

.loginLogo {
    height: 100px;
    margin-bottom: 15px;
}


h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
}

.disclaimer {
    font-size: 14px;
    font-weight: 500;
    color: black;
    margin-bottom: 10px;
    text-align: justify;
}

.tickCont {
    display: flex;
    gap: 8px;
    margin-top: -5px;
    position: relative;
    margin-bottom: 8px;
    font-weight: 500;

}

.appleButton {
    font-family: Arial, sans-serif;
    width: 100%;
    display: inline-block;
    background-color: black;
    color: #fff;
    font-size: 19px;
    padding: 12px 0px;
    border-radius: 20px;
    text-decoration: none;
}

.googleButton {
    margin-top: 15px;
    font-family: Arial, sans-serif;
    width: 100%;
    display: inline-block;
    background-color: white;
    color: black;
    font-size: 19px;
    padding: 12px 0px;
    border-radius: 20px;
    text-decoration: none;
}

.facebookButton {
    margin-top: 15px;
    font-family: Arial, sans-serif;
    width: 100%;
    display: inline-block;
    background-color: #4267B2;
    color: #fff;
    font-size: 19px;
    padding: 12px 0px;
    border-radius: 20px;
    text-decoration: none;
}



.foundersButton {
    font-family: Arial, sans-serif;
    margin-top: 20px;
    width: 100%;
    display: inline-block;
    background-color: #0a1b3d;
    color: #fff;
    font-size: 19px;
    padding: 12px 0px;
    border-radius: 20px;
    text-decoration: none;
}

.facebookIcon {
    height: 19px;
    margin-right: 8px;
}

.appleIcon {
    filter: invert(1);
    height: 19px;
    margin-right: 10px;
}

.googleIcon {
    height: 19px;
    margin-right: 10px;
}

.containerDownload {
    margin-top: 0px;
    top:45px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 100%;
}

.boxDownload {
    margin: auto auto;
    width: 90%;
    padding: 20px;   
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    text-align: center;   
}

.boxDownload h1 {
    color: hsl(72, 100%, 47%);
    font-size: 19px;
    margin-bottom: 20px;
    font-style: italic;
    font-weight: 800;
}

.PolicyEulaCont {
    display: flex;
    color: white;
}

.PolicyEulaCont p {
    display: flex;
    gap: 20px;
}

.PolicyEulaCont span {
    font-weight: bold;
    text-decoration: underline;
}

.refLink {
    font-size: 15px;
    color: black;
    font-weight: 600;
    border: none;
    display: flex;
    border-radius: 15px;
    padding: 14px 0px;
    justify-content: center;
    align-items: center;
    width: 100%;
}