.allAdmin {
  text-align: center;
  margin: 20px;
}

.AdminSeraches {
  gap: 10px;
  display: flex;
  position: sticky;
  width: calc(100% + 20px);
  margin-left: -10px;
  top: 10px;
  z-index: 100;
}

.h1 {
  font-size: 24px;
  margin-bottom: 20px;
}

.searchContainer {
  margin: 20px 0;
  background-color: rgb(255, 255, 255);
  padding: 10px 5px;
  border-radius: 15px;
}

.inputLabel {
  display: block;
  margin-bottom: 5px;
}

.inputField {
  width: 100%;
  padding: 8px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.searchButton {
  padding: 5px 5px;
  font-size: 12px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.searchButton:hover {
  background-color: #0056b3;
}

.usersDataCont {
  margin-top: 20px;
}