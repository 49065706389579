.friendsList {
    margin-top: 0px;
    width: 100%;
    position: fixed;
    height: calc(100vh - 45px);
    display: flex;
    flex-direction: column;

}

.scrollList {
    position: relative;
    width: 100%;
    flex-wrap: wrap;
    overflow: auto;
    z-index: 0;
    margin-top: 100px;
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;

}


.scrollList::-webkit-scrollbar {
    display: none;
}

.dummychat2 {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 1
}

.seachContFR {
    color: #2a4260;
    position: sticky;
    width: 100%;
    justify-content: center;
    top: 55px;
    display: flex;
    margin: 10px 0px 0px 0px;
    z-index: 2;
}

.seachContFR input {
    font-size: 16px;
    width: 130px;
    border-bottom: 1px solid hsl(72, 100%, 47%);
    line-height: 1.2;
}

.seachContFR input::placeholder {
    font-size: 16px;
    letter-spacing: 1px;
    color: hsla(72, 100%, 47%, 65%);
}


.hint2 {
    margin-top: -50px;
    box-shadow: inset 0px 0px 5px black;
    font-family: 'BankGothic Lt BT', sans-serif;
    background-color: transparent;
    background-color: white;
    border-radius: 4px;
    border: 1px solid #2a4260;
    padding: 3px;
    outline: none;
    color: #09213f;
    font-size: 16px;
    width: 120px;
}

.fake {
    animation: gradientAnimationText 1s infinite linear;
}

.fakeBackGround {
    animation: gradientAnimationBackGround 3s infinite linear;
}

@keyframes gradientAnimationText {
    0% {
        color: rgb(181, 181, 181)
            /* Light grey */
    }

    50% {
        color: hsl(0, 0%, 45%);
        /* Dark grey */
    }

    100% {
        color: rgb(181, 181, 181)
            /* Light grey */
    }


}

@keyframes gradientAnimationBackGround {
    0% {
        background-color: rgb(25, 25, 25);
        color: rgb(5, 5, 5)
            /* Light grey */
    }

    50% {
        background-color: hsl(0, 0%, 22%);
        color: hsl(0, 0%, 24%);
        /* Dark grey */
    }

    100% {
        background-color: rgb(25, 25, 25);
        color: rgb(5, 5, 5)
            /* Light grey */
    }
}