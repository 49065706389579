.createPosth2 {
    color: white;
    background-color: hsla(0, 0%, 25%, 0.6);
    padding: 15px 0px 10px 0px;
    letter-spacing: 1px;
    font-size: 16px;
}

.creatPostElements {
    width: 100%;
    padding: 0px 20px;
    background-color: hsla(0, 0%, 13%, 1);
    display: flex;
    flex-direction: column;
    justify-content: space-around;

}

.CreateCard {
    width: 100%;
    display: grid;
    grid-template-columns: auto 150px;
    border-radius: 20px;
    height: 42px;
    background-color: hsla(0, 0%, 10%, 0.9);
    margin-bottom: 10px;
}

.pickPostPhoto {
    height: 30px;
    padding: 5px;
    background-color: rgba(221, 221, 221, 0.652);
    border-radius: 5px;
}

.commanButtons {
    font-weight: 500;
    background-color: rgba(221, 221, 221, 0.652);
    height: 20px;
    display: flex;
    align-items: center;
    padding: 0px 15px;
    border-radius: 15px;
    font-size: 12px;
}

.PostCreateText {

    width: 100%;
    background-color: hsla(0, 0%, 13%, 1);
    padding: 10px 10px;
}

.PostCreateText p {
    width: fit-content;
    color: white;
    margin-left: 0px;
    margin-right: 0px;
    font-weight: 500;

}



.PostCreateText input {
    background-color: rgba(255, 255, 255, 0);
    border: 0px;
    border-radius: 0px;
    border-bottom: 1px solid white;
    color: white;
    width: calc(100% - 20px);
    font-size: 14px;
}

.PostCreateText input::placeholder {
    color: white;
}

.soloPostP {
    background-color: hsla(0, 0%, 13%, 1);
    color: white;
    text-align: left;
    padding: 2px 10px;
    font-size: 12px;

}

.soloPostP2 {
    background-color: hsla(0, 0%, 13%, 1);
    color: white;
    text-align: left;
    padding: 2px 10px;
    font-size: 14px;
}

.confirmPostCont {
    display: flex;
    position: sticky;
    top: 0px;
    background-color: hsla(0, 0%, 13%, 1);
    width: 100%;
    padding-bottom: 12px;
    padding-top: 12px;
    margin-bottom: 10px;
    z-index: 5;
    border-bottom: 1px solid black;
}

.confirmPostCont p {
    background-color: hsla(72, 100%, 47%, 65%);
    width: 150px;
    height: 25px;
    align-items: center;
    display: flex;
    justify-content: center;
    border-radius: 15px;
    font-size: 16px;
    font-weight: 500;
}

.twoInOneButton {
    display: flex;
    width: 150px;
    height: 25px;
    border-radius: 15px;
}

.postInCreatorCont {
    position: sticky;
    top: '150px'
}



.CreatingPost {
    position: fixed;
    display: flex;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.654);
}

.CreatingPost p {
    display: flex;
    align-items: center;
    justify-content: center;
    color: gradientAnimationBackGround 2s infinite linear;
    font-weight: 500;
    font-size: 16px;
}

.pickPostPhoto:active {
    filter: invert(1);

}

.commanButtons:active {
    filter: invert(1);
}

.interOnClick:active {
    filter: invert(1);
}

.PostPreview {
    color: white;
    margin: 20px;
    top: 60px;
    font-style: italic;
}

.PostPreview2 {
    color: white;
    margin: 20px;
    position: sticky;
    top: 100px;
}