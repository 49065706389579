.experienceCont {
    background-color: hsla(0, 0%, 13%, 1);
    margin-bottom: 10px;
}

.coachExpCardCont {
    padding: 10px 10px;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 80px auto;
    border-bottom: 10px solid hsla(0, 0%, 10%, 0.9);
}

.editExperience {
    padding: 3px;
    width: 25px;
    margin-right: 30px;
    grid-column: 1 / 3;
    grid-row: 1 / 2;
    z-index: 1;
}

.coachExpCardHead {
    display: flex;
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    margin-left: 0px;
    margin-top: 0px;
}

.expImage {
    width: 70px;
    height: 70px;
    border-radius: 10px;
}

.expInfo {
    height: 100%;
    margin-left: 10px;
    margin-top: 0px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    color: white;

}

.expCardDescriptionCont {
    grid-column: 1 / 3;
    grid-row: 2 / 3;
    overflow-y: scroll;
    width: 100%;
}

.expCardDescription {
    font-size: 14px;
    color: white;
    font-weight: 500;
    text-align: left;
    white-space: pre-wrap
}

.ExpCertHolder {
    background-color: rgba(0, 0, 0, 0.95);
    position: fixed;
    top: 45px;
    z-index: 800;
    height: calc(100% - 95px);
    display: flex;
    align-items: center;
}

.CreateEditExpPage {
    height: 100%;
    overflow-y: scroll;
}

.ExpCertHolder img {
    width: calc(100% - 20px);
}

.createEditExpCont {
    background-color: rgba(0, 0, 0, 1);
    position: fixed;
    width: 100%;
    height: calc(100% - 45px);
    top: 45px;
    z-index: 800;
}

.coachExpCardCont input {
    text-align: left;
    background: none;
    color: white;
    font-weight: 500;
    padding: 0px;
    font-size: 13px;
    border: 0px;
    border-bottom: 1px solid black;
    border-radius: 0px;
}

.coachExpCardCont textarea {
    text-align: left;
    background: none;
    color: white;
    font-weight: 500;
    padding: 0px;
    font-size: 13px;
    border: 0px;
    border-radius: 0px;
    width: 100%;
    height: 100%;
}

.addRevTextara {
    display: flex;
    text-align: left;
    background: none;
    color: white;
    font-weight: 500;
    padding: 3px 0px;
    font-size: 13px;
    border: 0px;
    border-radius: 0px;
    width: 100%;
    border-bottom: 1px solid black;
    margin-bottom: 10px;

}

.clearMediaOrEndDate {
    padding-top: 10px;
    display: flex;
    grid-column: 1 / 3;
    grid-row: 3 / 4;
    width: 100%;
}

.submitOrCancelExp {
    padding-top: 10px;
    display: flex;
    grid-column: 1 / 3;
    grid-row: 4 / 5;
    gap: 40px;
}