.buttonWithImage {
    display: grid;
    grid-template-columns: auto 30px;
    background-color: hsla(0, 0%, 10%, 0.9);
    height: 40px;
    width: 170px;
    justify-content: space-evenly;
    border-radius: 15px;
    align-items: center;
}

.buttonWithImage img {
    height: 25px;
}

.groupCardCont {
    background-color: hsla(0, 0%, 13%, 1);
    padding: 10px 10px;
    margin-bottom: 10px;
    display: grid;
    width: calc(100% - 20px);
    border-radius: 15px;
    grid-template-columns: 100px auto;
    grid-template-rows: 120px auto;
    
}

.smallGroupCardCont {
    background-color: hsla(0, 0%, 13%, 1);
    padding: 10px 10px;
    margin-bottom: 10px;
    display: grid;
    grid-template-columns: 30px auto;
    grid-template-rows: 30px auto;
    width: calc(100% - 20px);
    border-radius: 15px;
}

.timeViewContInGroup p {
    color: white;
    margin-left: 10px;
    width: fit-content;
    font-weight: 600;
}

.findGroupsHeader {
    background-color: hsla(0, 0%, 13%, 1);
    display: flex;
    width: 100%;
    margin-bottom: 10px;
    gap: 20px;
    padding: 10px 10%;
}

.GroupMeetingTimeCont {
    background-color: hsla(0, 0%, 15%, 0.95);
    height: 30px;
    width: calc(100% - 20px);
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;

}

.GroupMeetingTimeCont p {
    font-weight: 500;
}