.tagRequestTitile {
    background-color: rgba(17, 17, 17, 0.85);
    color: white;
    padding: 10px 10px;
    margin-bottom: 10px;
}

.video-container {
    position: relative;
    max-width: 100%;
    margin-bottom: 10px;
}

.thumbCont {
    position: absolute;
    display: flex;
    justify-content: center;
    width: 100%;
    z-index: 500;
    margin-bottom: -100%;
}