* {
  box-sizing: border-box;
  margin: auto;
  text-align: center;
  font-family: 'Helvetica' !important;
}

html {
  /*
  background-image: url('./Pics/Background3.jpg');
  background-size: 150%;
  background-position-x: center;
  background-position-y: top;
  */
  background-color: rgba(0, 0, 0, 0.906);
}

body {
  padding: 0 0;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 100%;
  width: 100%;
  touch-action: manipulation;
}

#root {

  display: grid;
  grid-template-columns: 100%;
  width: 100%;
  padding: 0 0;
  margin: 0 auto;
}


.App {
  display: grid;
  grid-template-columns: 100%;
  width: 100%;
  padding: 0 0;
  margin: 0 auto;

}

.homeLogo {
  position: fixed;
  top: 8px;
  border-radius: 8px;
  height: 31px;
  margin-left: 18px;
  z-index: 6;
}

.scrollHelperContainer {
  margin-top: 0px;
  width: 100%;
  position: fixed;
  height: calc(100vh - 45px);
  display: flex;
  flex-direction: column;
}

.scrollHelper {
  display: grid;
  margin-bottom: 10px;
  grid-template-columns: 100%;
  width: 100%;
  overflow: scroll;
  z-index: 1;
  -ms-overflow-style: none;
  scrollbar-width: none;

}


.scrollHelper::-webkit-scrollbar {
  display: none;
}

.Pwhite p {
  color: white;
}

input:focus,
textarea:focus {
    outline: none;
}