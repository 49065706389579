.singleButton {
    font-size: 15px;
    color: black;
    font-weight: 600;
    border: none;
    display: flex;
    border-radius: 15px;
    background-color: hsl(72, 100%, 47%);
    padding: 14px 0px;
    justify-content: center;
    align-items: center;
    width: 120px;
}

.singleButton2 {
    font-size: 12px;
    color: black;
    font-weight: 600;
    border: none;
    display: flex;
    border-radius: 15px;
    background-color: #f5f5f5;
    padding: 13px 0px;
    justify-content: center;
    align-items: center;
    width: 75px;
}


.doublbutCont {
    display: flex;
    flex-direction: column;
    height: fit-content;
    width: fit-content;
    gap: 15px;
}

.doubleFriendsButton {
    display: flex;
    padding: 0px;
    gap: 15px;
}

.doubleFriendsButton img {
    height: 43px;
    filter: grayscale(1);    
    background-color: aqua;
    padding: 3px 5px;
    border-radius: 15px;
}