.SubTitle {
    color: white;

    margin-bottom: 10px;
}

.imagesWithTextUnderCont {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;
}

.imagesWithTextUnderCont div {
    width: 100%;

}

.imagesWithTextUnderCont div img {
    margin-bottom: -5px;
}

.currentSubTime {
    padding: 10px 5px 0px 5px;
    color: white;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.currentSubTime p {
    font-size: 18px;
}


.subGroupCont {
    display: flex;
    width: 100%;
    margin-top: 0px;
    justify-content: center;
}

.subTable {
    color: white;
    border: 2px solid #686868;
}

.subTable th {
    height: 70px;
    font-size: 20px;
}

.subTable td {
    padding: 10px;
}

.subTable td img {
    height: 40px;
}

.resotePurchCont {
    height: 50px;
    width: 30%;
    background-color: hsla(0, 0%, 24%, 0.9);
    padding: 9px 15px;
    gap: 6px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.resotePurchCont p {
    color: White;
    margin: 0px;
}

.subscription-card {
    background-color: hsla(0, 0%, 24%, 0.9);
    border: 1px solid #686868;
    border-radius: 15px;
    padding: 10px;
    margin: 6px;
    text-align: center;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
    cursor: pointer;
    color: white;
}

.subscription-card:hover {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.card-header {
    border-bottom: 1px solid #eee;
    padding-bottom: 8px;
    margin-bottom: 8px;
}

.product-name {
    font-size: 1.5em;
    margin: 0;
}

.price-per-month {
    font-size: 1.2em;
    color: #28a745;
    margin: 0;
}

.card-body {
    padding-top: 8px;
}

.total-price {
    font-size: 1.1em;
    margin: 8px 0;
}

.save-amount {
    font-size: 1em;
    color: hsl(72, 100%, 47%);
}

.action-buttons {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
}

.restore-purchase {
    padding: 10px 15px;
    background-color: hsl(72, 100%, 47%);
    border: none;
    border-radius: 5px;
    color: black;
    cursor: pointer;
    transition: background-color 0.2s;
}