.listjs {
    margin-top: 0px;
    width: 100%;
    position: fixed;
    height: calc(100vh - 104px);
    display: flex;
    flex-direction: column;
}


.doubleSearch {
    padding: 0px 15px;
    gap: 10px;
    row-gap: 0px;
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 50% 50%;
    justify-content: center;
    align-self: center;
    position: fixed;
    top: 205px;
    z-index: 2;
}

.seachCont {
    color: white;
    display: grid;
    grid-template-columns: min-content 1fr;
    border-radius: 12px;
    background-color: hsla(0, 0%, 25%, 80%);
    width: 100%;
    margin-bottom: 5px;
    height: 24px;
}

.seachCont label {
    color: hsl(72, 100%, 47%);
    margin-left: 15px;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1PX;
    font-style: italic;
}


.seachContLocation {
    color: white;
    display: grid;
    grid-template-columns: min-content 1fr;
    border-radius: 12px;
    background-color: hsla(0, 0%, 25%, 80%);
    grid-column-start: 1;
    grid-column-end: 3;
    width: 80%;
    margin-bottom: 0px;
    height: 24px;
}

.seachContLocation label {
    color: hsl(72, 100%, 47%);
    margin-left: 15px;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1PX;
    font-style: italic;
}

.seachContLocation input {
    width: 100%;
    border: 0px solid black;

}


.seachCont input {
    width: 100%;
    border: 0px solid black;

}

.scrollList2 {
    height: fit-content;
    position: relative;
    width: 100%;
    flex-wrap: wrap;
    overflow: auto;
    z-index: 0;
    -ms-overflow-style: none;
    scrollbar-width: none;

}


.scrollList2::-webkit-scrollbar {
    display: none;
}

.nonActiveOthers {
    border-top: 1px solid hsla(72, 100%, 47%, 65%);
    border-bottom: 1px solid hsla(72, 100%, 47%, 65%);
    margin-bottom: 10px;
    color: white;
    padding: 10px 10px;
    text-align: left;
    font-size: 16px;
    font-weight: 500;


}

.shareContainerList {
    color: white;
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 10px;
}

.shareDivList {
    height: 100%;   
    gap: 10px;
}

.shareContainerList p {
    font-size: 12px;
    text-align: left;
    margin-bottom: 30px;
    margin-left: 10px;
}