.smallprofileCont {
  margin: 0px 10px 0px 10px;
  width: calc(100% - 20px);
  position: fixed;
  top: 55px;
  justify-content: center;
  display: grid;
  grid-template-columns: 66px 1fr;
  height: 80px;
  grid-template-rows: 100%;
  color: whitesmoke;
  letter-spacing: 1px;
  z-index: 2;
  background-color: hsla(0, 0%, 25%, 80%);
  padding: 7px;
  border-radius: 15px;
}

.smallprofilePic {
  grid-column-start: 1;
  grid-row-start: 1;
  grid-column-end: 2;
  grid-row-end: 2;
  margin-left: 0;
  height: 100%;
  border-radius: 10px;
}



.smallprofInfo {
  display: flex;
  flex-direction: column;
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 2;
  margin-top: 13px;
  margin-left: 10px;
  justify-content: left;
  text-align: left;
  width: calc(100% - 15px);

}


.smallProfDiscover {
  position: absolute;
  right: 5px;
  bottom: 0px;
  border-radius: 10px;
  width: 40px;

}

.smallProfDiscover img {
  width: 100%;
}

.smallprofInfo p {
  font-size: 16px;
  letter-spacing: 1px;
}



.smallname {
  text-align: left;
  width: 100%;
  font-weight: 500;
  font-size: 16px;
}

.smallconnectsbtn {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 2;
  grid-row-end: 3;
  margin-top: 5px;
  margin-left: 10px;
  display: flex;
  z-index: 2;


}

.smallconnectsbtn img {
  width: 40px;
}

.smallprofInfo h3 {
  margin-left: 0;
}

.smallprofInfo p {
  margin-left: 0;
}

.smalleditButton {
  width: 18px;
  position: relative;
  right: 5px;


}

.smallageCont {
  width: 100%;
  z-index: 3;
}

.smallconfirmInts {
  position: absolute;
  bottom: 7px;
  letter-spacing: 1px;
  justify-content: center;
  text-align: center;
  display: flex;
  padding: 3px 10px;
  background-color: hsla(72, 100%, 47%, 0.579);
  color: black;
  cursor: pointer;
  font-family: 'BankGothic Lt BT', sans-serif;
  font-size: 12px;
  border-radius: 8px;
  z-index: 2;
  height: 20px;
}

.removeFromList {
  position: absolute;
  bottom: 7px;
  letter-spacing: 1px;
  justify-content: center;
  text-align: center;
  display: flex;
  padding: 1px 10px;
  border: 1px solid hsl(0, 100%, 50%);
  background-color: rgb(179, 179, 179);
  color: black;
  cursor: pointer;
  font-family: 'BankGothic Lt BT', sans-serif;
  font-size: 12px;
  border-radius: 8px;
  z-index: 2;
  margin-left: 100px;
  width: 90px;
  height: 20px;
}

.removeFromList p {
  position: relative;
  letter-spacing: 1px;
  justify-content: center;
  text-align: left;
  display: flex;
  color: black;
  cursor: pointer;
  font-family: 'BankGothic Lt BT', sans-serif;
  font-size: 12px;
  border-radius: 8px;
  z-index: 2;

}

.removeFromList img {
  height: 15px;

}

.clockHelperInSmallProf {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 2;
  margin-top: -10px;
  margin-right: 0px;
}