.NoPlayersCont {
    position: relative;
    margin-bottom: 10px;
    color: white;
    z-index: 0;
    border-radius: 15px;
    gap: 10px;
    display: flex;
    flex-direction: column;
    font-size: 16px;
    font-weight: 500;
}

.Nosportsenthusiasts {
    background-color: hsla(0, 0%, 25%, 80%);
    padding: 10px 10px;
    font-weight: 500;
    width: calc(100% - 20px);
    margin-bottom: 10px;
    color: white;
    border-radius: 15px;
    line-height: 1.3;
}

.shareCont {
    height: 300px;
    margin: auto;
    width: 300px;
    background-image: url('../../Pics/2024small.png');
    background-size: contain;
    display: grid;
    grid-template-columns: repeat(6, 50px);
    grid-template-rows: repeat(6, 50px);
    border: 1px solid black;
}

.shareContProfileImage {
    border-radius: 15px;
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 3;
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    margin-top: 10px;
    margin-left: 10px;
}

.shareContName {
    font-size: 18px;
    margin: 0;
    grid-column-start: 3;
    grid-column-end: 7;
    grid-row-start: 1;
    grid-row-end: 2;
    margin-top: 10px;
    text-align: left;
    padding: 0px 10px;
    color: black;
}

.shareContInterest {
    grid-column-start: 4;
    grid-column-end: 7;
    grid-row-start: 2;
    grid-row-end: 3;
    margin-left: 15px;
    text-align: left;
    color: hsl(72, 100%, 47%);
}

.shareContInterestImg {
    max-width: 90%;
    background-color: #ddd;
    padding: 5px;
    border-radius: 15px;
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 2;
    grid-row-end: 3;
    margin-left: 10px;
}

.shareContTime {
    grid-column-start: 1;
    grid-column-end: 7;
    grid-row-start: 3;
    grid-row-end: 4;
    margin-left: 10px;
    margin-top: 10px;
    color: black;
}

.shareContLocation {
    grid-column-start: 1;
    grid-column-end: 7;
    grid-row-start: 3;
    grid-row-end: 4;
    margin-left: 10px;
    margin-bottom: 0px;
    color: black;
}

.shareContLogo {
    grid-column-start: 5;
    grid-column-end: 7;
    grid-row-start: 4;
    grid-row-end: 6;
    height: 90%;
    margin-right: 10px;
}

.shareContJoinMe {
    grid-column-start: 1;
    grid-column-end: 5;
    grid-row-start: 4;
    grid-row-end: 6;
    margin-left: 10px;
    color: hsl(72, 100%, 47%);
    line-height: 1.3;
}

.join-button {
    gap: 15px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    display: flex;
    grid-column-start: 1;
    grid-column-end: 7;
    grid-row-start: 6;
    grid-row-end: 7;
}

.shareButton:active {
    filter: invert(1);
}