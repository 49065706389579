.topFeedBar {
    display: flex;
    margin-top: 10px;


}

.ppwithplusPost {
    height: 50px;
    margin-left: 10px;
}

.miniPPInFeedShortCut {
    height: 50px;
    height: 50px;
    border-radius: 15px;
    animation: gradientAnimationBackGround .5s infinite linear;
}

.plusInFeedShortCut {
    position: absolute;
    height: 20px;
    left: 30px;
    margin-top: 30px;
}

.FeedShortCutHeaderCont {
    margin-top: 0px;
    width: 100%;
    display: flex;
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;

}


.FeedShortCutHeaderCont::-webkit-scrollbar {
    display: none;
}


#FeedShortCutHeaderContButtonHelper {
    position: absolute;
    width: calc(33.33% - 10px);
    aspect-ratio: 1;
    z-index: -5;
}

.FeedShortCutSlider {
    display: flex;
    width: fit-content;
    gap: 10px;
    padding: 10px 10px;
    padding-bottom: 0px;
}

.FeedShortCutHeaderContButton {
    background-color: hsla(0, 0%, 13%, 1);
    border-radius: 15px;
}

.editFirtShortcut {
    display: grid;
    grid-template-rows: 50% 50%;
    grid-template-columns: 100%;
}



.refreshInFeedImg {
    height: 50px;
    width: 50px;
    background-color: hsla(0, 0%, 10%, 0.9);
    padding: 9px;
    border-radius: 15px;
    left: 70px;
    position: absolute;
}

.editFirtShortcut2 {
    padding: 1.2% 1.2%;
    display: grid;
    grid-template-rows: 45% 10% 45%;
    grid-template-columns: 20% 20% 20% 20% 20%;

}

.findInshortCutCont {
    height: 100%;
    width: 100%;
    grid-row: 1 / 2;
    grid-column: 1 / 6;
    border-radius: 20px;
    display: grid;
    grid-template-columns: 20% 80%;
    grid-template-rows: 100%;

}

.findInshortCutCont img {
    margin-left: 5px;
    height: 20px;
}

.findInshortCutCont h6 {
    width: 100%;
    line-height: 1;
    font-size: 16px;
}

.findInshortCutCont h5 {
    text-align: left;
    margin-left: 0px;
    color: white;
    font-size: 10px;

}

.nearByImgCont {
    grid-row: 3 / 4;
    grid-column: 1 / 2;
    width: 100%;
    aspect-ratio: 1;
    display: flex;
    flex-direction: column;
}

.nearByImgCont img {
    width: 45%;
    margin-bottom: 5%;
}

.nearByImgCont h5 {
    color: hsl(72, 100%, 47%);
    font-size: 8px;
}

.favShorCutContButton1 {
    padding: 5px 5px;
    background-color: hsla(0, 0%, 10%, 0.9);
    grid-row: 3 / 4;
    grid-column: 2 / 4;
    width: 95%;
    border-radius: 15px;
    display: grid;
    grid-template-columns: 50% 50%;
}

.favShorCutContButton2 {
    padding: 5px 5px;
    background-color: hsla(0, 0%, 10%, 0.9);
    grid-row: 3 / 4;
    grid-column: 4 / 6;
    width: 95%;
    border-radius: 15px;
    display: grid;
    grid-template-columns: 50% 50%;
}

.noFavShortcut {
    display: flex;
    padding: 5px 5px;
    grid-row: 3 / 4;
    grid-column: 1 / 6;
    height: 40px;

}

.noFavShortcut h5 {
    text-align: left;
    margin-left: 0px;
    color: white;
    font-size: 10px;

}

.favShortCutCont {
    display: flex;
    color: grey;
    height: 100%;
    border-radius: 15px;
    align-items: center;
}


.favShortCutCont img {
    height: 30px;
    width: 30px;
    filter: invert(1) grayscale(1);
}

.shortCutCountNumber {
    color: hsl(72, 100%, 47%);
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 1px;
    width: 65%;
    align-items: center;
    display: flex;
    justify-content: center;
}


.feedPostsCont {
    position: fixed;
    width: 100%;
    top: 45px;
    left: 0px;
    height: calc(100vh - 45px);
    background-color: none;
    margin-top: 0px;
    display: flex;
    flex-direction: column;

}


.topRefresh {
    display: flex;
    background-color: #f5f5f59c;
    color: black;
    height: 35px;
    width: 35px;
    margin-bottom: 10px;
    border-radius: 15px;
    align-items: center;

}

.topRefresh img {
    height: 70%;
    filter: invert(1);
}

.plusImage {
    position: absolute;
    width: 22px;
    margin-top: 39px;
    margin-left: 29px;
}

.encouragePost {
    font-weight: 700;
    justify-self: center;
}



.feedWorkout {
    display: flex;
    background-color: hsla(72, 100%, 47%, 65%);
    color: black;
    height: 50px;
    width: 120px;
    border-radius: 15px;
    justify-content: center;
    align-items: center;
}

.feedWorkout:active {
    background-color: #0606069c;
    color: white;
}


.feedHelpersCont {
    text-align: center;
    gap: 10px;
    display: flex;
    position: absolute;
    height: 35px;
    width: fit-content;
    margin: 0px auto;
    bottom: 0;
    margin-bottom: 10px;
}

.feedHelpersCont p {
    color: black;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f5f5f59c;
    margin: auto;
    border-radius: 10px;
    font-size: 13px;

}

.feedHelpersCont div {
    color: black;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f5f5f59c;
    width: 50px;
    margin: auto;
    border-radius: 10px;
    font-size: 13px;

}

.feedHelpersCont div:active {
    filter: invert(1);
}

.feedHelpersCont img {
    height: 75%;
}

.ProfileHeader {
    position: relative;
    padding: 10px 0px 45px 0px;
    margin-bottom: 10px;
    border-bottom: 1px solid black;
    z-index: 20;
    background-color: none;
}

.bigSearch {
    border-bottom: 1px solid hsla(72, 100%, 47%, 65%);

}


.doYouWatToSetCont {
    border-radius: 20px;
    background-color: white;
    width: 90%;
    padding: 20px 20px;
    font-weight: 500;
    line-height: 1.2;

}

.doYouWatToSetCont p {
    text-align: left;
}

.feedFilter {
    color: white;
    display: flex;
    border-radius: 15px;
    background-color: hsla(0, 0%, 13%, 1);
    padding: 10px;
    width: 100%;
    margin-bottom: 10px;
    height: 50px;
}

.feedFilter label {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: hsla(0, 0%, 10%, 0.9);
    color: hsl(72, 100%, 47%);
    margin: auto;
    font-size: 14px;
    font-weight: 800;
    width: 170px;
    height: 35px;
    letter-spacing: 1PX;
    font-style: italic;
    border-radius: 15px;

}


.feedFilter p {
    background-color: hsla(72, 100%, 47%, 65%);
    width: fit-content;
    width: 63px;
    columns: black;
    height: 20px;
    align-items: center;
    display: flex;
    justify-content: center;
    border-radius: 15px;
    font-size: 14px;
    font-weight: 500;
}

.selectiveImgFilter {
    height: 50px;
    padding: 5px;
    border-radius: 15px;
}


.subTitle h3 {
    color: hsl(72, 100%, 47%);
    letter-spacing: 2px;
    font-size: 16px;
}

.feedTimeview p {
    text-align: center;
    font-size: 14px;
}

.activeBlack {
    -webkit-touch-callout: none;
    /* Disable the callout (context menu) */
    user-select: none;
    /* Disable text selection */
}

.activeBlack:active {
    background-color: black;
    border-radius: 20px;
    position: relative;
    color: white;
    filter: none;
    transform: scale(0.95);

}

.activeBlack:focus {
    outline: none;
    /* Remove default outline */
}

.activeBlack2:active {
    background-color: black;
    border-radius: 20px;
    color: white;
    filter: none;
}

.eventContInFeed {
    transition: max-height 1s ease-in-out, opacity 1s ease-in-out;
    -webkit-transition: max-height 1s ease-in-out, opacity 1s ease-in-out;
    overflow: hidden;
    max-height: 100%;
    margin-bottom: 0px;
    padding-bottom: 0px;
 
}


.eventContInFeed.hide {
    max-height: 0;
    padding: 0;
    /* Optional: to remove any padding when hidden */
}

.yesAndNo p {
    padding: 10px 0px;
    width: 30%;
    border-radius: 20px;
    background-color: rgb(234, 234, 234);
}

.interestedInCont {
    display: grid;
    width: 100%;
    justify-content: space-between;
    padding: 0px 10px;
    margin-bottom: 10px;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
    grid-template-rows: 100%;
}

.interestedInButtonCont {
    background-color: hsla(0, 0%, 13%, 1);
    width: 100%;
    border-radius: 15px;
    aspect-ratio: 3;
    display: flex;
}


.interestedInButtonCont h5 {
    color: rgba(255, 255, 255, 0.689);
    font-weight: 500;
}

.interestedInButtonCont h6 {
    color: hsla(72, 100%, 47%, 65%);
    font-weight: 500;
    font-size: 13px;
}