:root {
    --dark-bg: hsla(0, 0%, 13%, 1);
    --darker-bg: hsla(0, 0%, 10%, 0.9);
    --accent-color: hsl(72, 100%, 47%);
}

.download-now {
    z-index: 1000;
    top: -45px;
    position: relative;
    background-color: hsla(0, 0%, 10%, 1);
    user-select: none;
}


.landing-page {
    text-align: center;
    padding: 20px;
}

.header {
    background-color: var(--dark-bg);
    padding: 10px 0;
}



.intro {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--dark-bg);
    margin-bottom: 10px;
}

.logoDownload {
    height: 60px;
    width: auto;
    margin-right: 10px;
    margin-left: 20px;
    padding: 10px 0px;
}

.titleDownload {
    color: white;
    font-size: 26px;
    letter-spacing: 1px;
    width: 100%;
    text-align: left;
    height: 100%;
    display: flex;
    margin: auto;

}

.subtitleDownload {
    color: white;
    font-size: 14px;
    width: 100%;
    text-align: left;
    height: 100%;
    display: flex;
    margin: auto;
}


.description {
    font-size: 1.2em;
    margin: 10px 0;
}

.download-buttons {
    margin: 20px 0;

}

.download-buttons a {
    margin: 0 10px;

}

.download-buttons img {
    width: 150px;
    height: auto;
    box-shadow: 0px 0px 10px rgba(119, 119, 119, 0.5);

}

.features {
    padding: 20px 0;
    background-color: var(--dark-bg);
}

.section-title {
    font-size: 2em;
    margin-bottom: 20px;
}

.feature-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.featureItemCont {
    color: white;
}

.feature-item {
    flex: 1 1 45%;
    margin: 10px;
    padding: 20px;
    border-radius: 10px;
    padding-bottom: 0px;
    margin-bottom: 0px;   
}

.feature-imgCont {
    height: calc(100vh - 300px);
    width: 100%;
    margin-bottom: 10px;
}

.feature-img {
    max-height: 100%;
    max-width: 100%;

}

.feature-item h3 {
    font-size: 1.5em;
    margin-bottom: 10px;
}

.feature-item p {
    font-size: 14px;
    text-align: center;

}

.screenshots {
    padding: 20px 0;
}

.download-bar {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0px 10px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    width: 100%;  
    margin: auto;
    margin-top: -10px;
    position: absolute;
    z-index: 5;
}

.download-bar-item {
    display: flex;
    align-items: center;
    margin: 8px 0;
    font-size: 16px;
    color: white;
}

.download-bar-item .icon {
    margin-right: 5px;
    width: 25px;   
}

.user-count {
    font-weight: bold;
    font-size: 18px;
    color: white;
}