.postCont {
    background-color: hsla(0, 0%, 13%, 1);
    padding: 10px 0px;
    margin-bottom: 10px;
    user-select: none;
}

.postCreatorInfo {
    display: flex;
    width: fit-content;
    width: 100%;
    margin-bottom: 10px;
}

.creatorProfile {
    width: 40px;
    height: 40px;
    border-radius: 5px;
    margin-right: 0px;
    margin-left: 10px;
}

.postCreatorInfo p {
    margin-left: 10px;
    color: white;
    font-weight: 500;
}

.doubleTag {
    height: fit-content;
    margin-left: 10px;
    display: flex;
    color: white;
    gap: 5px;
    margin-bottom: 5px;

}

.doubleTag p {
    font-weight: 600;
    font-size: 12px;
    width: fit-content;
}

.doubleTag span {
    font-size: 12px;
}

.captionandPhoto {
    width: 100%;
    background-color: rgb(95, 125, 125);
    display: flex;
    padding: 0px 10px;
}

.postCaption {
    color: white;
    font-size: 14px;
    text-align: left;
    padding: 0px 10px;
}

.playButton {
    position: absolute;
    width: 80px;
    height: 80px;
    padding: 15px;
    border-radius: 50%;
    align-self: center;
    filter: invert(1);
    background-color: rgba(228, 228, 228, 0.839);
}

.postSportandTage {
    display: flex;

}


.postSport {
    position: relative;
    width: 30px;
    filter: grayscale(1);
    background-color: aliceblue;
    padding: 5px;
    border-radius: 10px;
    margin-left: 0px;


}

.smallBarInPostCont {
    display: grid;
    grid-template-columns: 2fr 2fr 3fr 3fr;
}

.likeCont {
    padding-right: 0px;
    margin-top: 3px;
    margin-left: 10px;
    width: fit-content;
    display: flex;
}

.likeCont img {
    width: 30px;
    padding: 0px 10px 0px 0px;


}

.likeCont p {
    color: white;
    font-weight: 500;
    font-size: 14px;
    padding-right: 30px;
}

.smallPosbuttons {
    color: black;
    background: rgb(213, 213, 213);
    font-size: 12px;
    padding: 3px 8px;
    border-radius: 10px;
    font-weight: 500;
}

.smallPosbuttons:active {
    filter: invert(1);
}

.reportPostChoises {
    background-color: rgba(0, 0, 0, 0.491);
    top: 0px;
    left: 0px;
}

.repImgInPost {
    filter: invert(1);
    width: 20px;
    position: absolute;
    margin-top: 10px;
    right: 30px;
}

.postCommentsCont {
    padding: 0px 10px;
}

.video-container {
    position: relative;
    width: 100%;
}

.custom-video-player {
    width: 100%;
    height: auto;
    background-color: black;
}

.story-progress-container {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}

.progress-bar-track {
    position: relative;
    height: 4px;
    background-color: rgba(255, 255, 255, 0.3);
}

.progress-bar-fill {
    height: 100%;
    background-color: #fff;
}