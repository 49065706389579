.meetingCardInfo {
    display: grid;
    grid-template-columns: auto 70px 50px;
}

.doubleTagInMeeting {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    gap: 5px;
}

.doubleTagInMeeting p {
    font-weight: 500;
    font-size: 12px;
    text-align: left;
    margin-left: 0px;
}

.timeInMeeting p{
    font-size: 11px;
    font-weight: 500;
    letter-spacing: 1px;
    text-align: right;
    color: white;
}