.container {
    padding: 20px;
    background-color: #181818;
    color: #fff;
  }
  
  .header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .profile {
    margin-right: 15px;
  }
  
  .profile-image {
    width: 50px;
    height: 50px;
    border-radius: 25px;
  }
  
  .header-text {
    font-size: 24px;
    font-weight: bold;
  }
  
  .feature-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .feature-card {
    background-color: #222;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    flex: 1;
    margin: 0 10px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .feature-card:hover {
    background-color: #333;
  }
  
  .feature-icon {
    width: 40px;
    height: 40px;
    margin-bottom: 10px;
  }
  
  .info-section {
    margin-bottom: 20px;
  }
  
  .info-header {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .favorite-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .favorite-image {
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }
  
  .user-count, .group-count {
    font-size: 16px;
  }
  