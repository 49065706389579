.newHeaderCont {
    display: grid;
    width: 100%;
    justify-content: space-between;
    padding: 0px 10px;
    margin-top: 10px;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
    grid-template-rows: 100%;
}

.subHeader {
    background-color: hsla(0, 0%, 13%, 1);
    width: 100%;
    border-radius: 15px;
    padding: 0px 10px 10px 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.subHeaderMainIMG {
    margin-top: -7px;
    width: 70%;
    margin-bottom: 5px;
}

.subHeader h3 {
    color: rgb(230, 230, 230);
}

.subHeaderButtonInfoImg {
    width: 17px;
    margin-right: -8px;
}

.nearbyTitle {
    display: flex;
    padding: 5px;
    justify-content: left;
    gap: 5px;


}

.nearbyTitle img {
    width: 10%;
    margin: 0px;



}

.nearbyTitle h4 {
    font-size: 15px;
    text-align: left;
    margin: 0px;
}

.newShorCutCont {
    display: flex;
    overflow: hidden;
    max-width: 100%;
    padding: 0 10px;
    gap: 10px;
}

.newShortCutButton {
    max-width: 100%;
    padding: 5px 5px;
    display: grid;
    grid-template-rows: 30px 2px auto;
    grid-template-columns: 0% 25% 25% 25% 25%;

}