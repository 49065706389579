.discoverCoachsHeaderCont {
    background-color: hsla(0, 0%, 25%, 0.6);
    height: 45px;
    display: flex;
    margin-bottom: 10px;
}

.discoverCoachsHeader {
    display: flex;
    width: fit-content;
    gap: 10px;
}