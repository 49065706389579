.youAreNotRegistered {
    background-color: hsla(0, 0%, 25%, 0.6);
    padding: 10px;
    color: white;

}

.youAreNotRegistered h4 {
    font-weight: 500;
}

.coachCardCont {
    background-color: hsla(0, 0%, 13%, 1);
    padding: 10px 10px;
    margin-bottom: 10px;
    display: grid;
    grid-template-columns: 100px auto;
    grid-template-rows: 100px auto;
}

.coachCardProfilePic {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    width: 100%;
    aspect-ratio: 1;
    border-radius: 10px;
}

.coachMainInfoCont {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
    width: 100%;
    height: 100%;
    overflow: scroll;
}

.coachMainInfoCont h3,
h4 {
    color: white;
    margin-left: 10px;
    text-align: left;
}

.coachMainInfoCont h5 {
    color: white;
    margin-left: 10px;
    text-align: left;
    font-weight: 500;
}

.SportCont {
    padding: 3px 0px;
    margin-left: 10px;
    display: flex;
    width: fit-content;
}

.starsCont {
    display: flex;
    grid-column: 1 / 3;
    grid-row: 2 / 3;
    gap: 10px;
    margin-left: 0px;
    padding-top: 10px;
    color: white;
    font-weight: 500;
}

.bigCoachCardCont {
    background-color: hsla(0, 0%, 13%, 1);
    padding: 10px 10px;
    margin-bottom: 10px;
    display: grid;
    grid-template-columns: 100px auto;
    grid-template-rows: 100px 70px auto;
}

.bigStarsCont {
    display: flex;
    grid-column: 1 / 3;
    grid-row: 3 / 4;
    gap: 10px;
    margin-left: 0px;
    padding-top: 10px;
    color: white;
    font-weight: 500;
}

.callChatCont {
    grid-column: 1 /2;
    width: 100%;
    display: flex;
}

.withdraFromCoach {
    grid-column: 2 / 3;
    grid-row: 3 / 4;
    margin-right: 50px;
    margin-bottom: 0px;
}

.experienceHead {
    display: flex;
    width: 100%;
    height: 45px;

}

.plusInExp {
    width: 35px;
    padding: 3px;
}

.reviewsCont {
    padding: 10px;
    overflow-y: scroll;
    margin-bottom: 10px;
}

.singleReviewCont {
    display: flex;
    width: 100%;
}

.singleReviewCont p {
    text-align: left;
    color: white;
    margin-left: 0px;
    margin-bottom: 0px;
    padding: 3px 0px;
    font-weight: 500;
    font-size: 12px;
    white-space: pre-wrap
}

.singleReviewCont span {
    text-align: left;
}

.repImgInSingleReview {
    filter: invert(1);
    height: 20px;
    width: 40px;
    padding-left: 24px;
    padding-top: 2px;
    padding-bottom: 2px;
    position: relative;
    margin-right: 20px;
    display: flex;
    gap: 10px;
    justify-content: right;
}

.repImgInSingleReview img {
    height: 100%;
    margin-right: 0px;
}

.studentsList {
    max-height: 350px;
    overflow: scroll;
}

.singleStudentCont {
    background-color: hsl(0, 0%, 25%);
    padding: 5px 5px;
    margin-bottom: 10px;
    border-radius: 10px;
    width: calc(100% - 20px);
    height: 50px;
    display: grid;
    grid-template-columns: 45px auto 40px 50px;
}

.singleStudentProfilePic {
    width: 40px;
    border-radius: 5px;
}

.singleStudentCont p {
    color: hsl(72, 100%, 47%);
    text-align: left;
    margin-left: 10px;
    font-weight: 500;
    font-size: 14px;
}