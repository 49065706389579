.helpCont {
    padding-bottom: 60px;
    background-color: white;
}

.helpCont h1 {
    color: black;
    margin-top: 20px;
    margin-bottom: 10px;
}

.mailTo {
    margin-bottom: 10px;
}

.eulaandpolicy {
    display: flex;
width: 40%;
}

.eulaandpolicy p {
    text-decoration: underline;
}

.helpCont h3 {
    color: black;
    margin-bottom: 5px;
    margin-top: -5px;
}

.smallHelp {
    background-color: #f5f5f5e8;
    display: grid;
    grid-template-columns: 40% 60%;
    padding: 10px;
    margin: 5px;
    border: 1px solid #2980b9;
    border-radius: 5px;
}

.smallHelp img {
    padding: 0px;
    margin: auto;
    border-radius: 3px;
    border: 0px solid #2980b9;
}

.smallHelp p {
    text-align: left;
    margin-left: 0px;
    font-size: 14px;

}

.bigHelp {
    padding: 10px;
    background-color: #f5f5f5e8;
    display: flexbox;
    margin: 5px;
    margin-bottom: 25px;
    border: 1px solid #2980b9;
    border-radius: 5px;
}

.bigHelp img {
    padding: 0px;
    margin: auto;
    border-radius: 3px;
    width: 100%;

}

.bigHelp p {
    font-size: 14px;
    text-align: left;
    margin-bottom: 3px;

}


.privPol {
    height: 100%;
    display: grid;
    font-family: Arial, Helvetica, sans-serif;
    padding: 20px 10px;
    background-color: white;
}

.privPol h3 {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    text-align: left;
    justify-content: left;
    margin-left: 0px;
}

.privPol h4 {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    text-align: left;
    justify-content: left;
    margin-left: 0px;
    color: black;
}

.privPol p {
    font-size: 12px;
    font-family: Arial, Helvetica, sans-serif;
    text-align: left;
    justify-content: left;
}

.privPol ul {
    padding-left: 15px;
}

.privPol li {
    padding-left: 0px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12px;
    text-align: left;
    justify-content: left;
}

.delProfileButton {
    margin-top: 20px;
    text-decoration: none;
    background-color: rgba(0, 0, 0, 0.137);
    color: red;
    padding: 10px 20px;
    border: 1px solid black;
    border-radius: 15px;
    font-size: large;
    font-weight: 500;
}