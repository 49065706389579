.outLet {
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
  padding: 0 0;
  margin: 0 0;

}


.outLet nav {
  width: 100%;
}

.homeComponentCont {
  width: 100%;
  height: 100%;
}

.footerV {
  justify-content: center;
  display: flex;
  position: fixed;
  align-items: center;
  width: 100%;
  bottom: 0;
  height: 40px;
  border-top: 1px solid white;
  z-index: 10;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

.loadingCont {
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* Center the container */
  height: 100vh;
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
}

.loadingimg {
  background-image: url('../../Pics/Logos.png');
  background-size: contain;
  background-position: center;
  margin: 0 auto;
  display: flex;
  width: 130px;
  height: 130px;
  animation: pulse .8s infinite;
  /* Apply the 'pulse' animation with 1 second duration, repeating infinitely */
}


.mainBackGround {
  /*
  background-image: url('../../Pics/Background2.png');
  */
  mix-blend-mode: darken;
  filter: brightness(.85);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
  display: flex;
  width: 100%;
  height: 100%;
  z-index: -200;
}

.tempProfilePage {
  background-color: rgba(23, 23, 23, 0.906);
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -45px;
  width: 100%;
  height: 100vh;
  z-index: 50;
}

.editInfoCont {
  border-radius: 15px;
  padding: 10px;
  border: 1px solid white;
  position: absolute;
  top: 10%;
  left: 10px;
  z-index: 50;
  width: calc(100% - 20px);
  height: auto;
  background-color: hsla(0, 0%, 25%, 0.973);
  display: flex;
  flex-direction: column;
}

.editInfoCont input {
  width: 90%;
  margin: 10px auto;
  height: auto;
  padding: 5px;
  border-radius: 10px;
  color: black;
}

.ageInput {
  width: 80px;
  height: 30px;
  margin-top: 10px;
  color: black;
  font-size: 14px;
  font-weight: 500;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.editInfoCont textarea {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  margin: 10px auto;
  height: auto;
  padding: 5px;
  border-radius: 10px;
  color: black;
}

.editInfoCont div {
  width: fit-content;
  display: flex;
  gap: 10px;
}


.editInfoCont button {
  color: black;
  font-weight: 500;
  width: 80px;
  padding: 5px 0;
  border-radius: 10px;
  background-color: hsl(72, 100%, 47%);
  border: 0px;
}

.editInfoCont button:active {
  filter: grayscale(1);
}

.editInfoCont h3 {
  font-size: 16px;
  margin: 10px auto;
  color: hsl(72, 100%, 47%)
}

.fullScreenClean {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0px;
  left: 0px;
  padding-top: 80px;
  z-index: 50;
}

.fullScreenBlack {
  background-color: rgba(0, 0, 0, 0.9);
  position: fixed;
  height: calc(100vh);
  width: 100%;
  top: 0px;
  padding-top: 55px;
  padding-bottom: 59px;
  left: 0px;
  z-index: 50;
  display: flex;
  align-items: center;
}

.phoneNumberCont {
  width: 100%;
  padding: 30px 30px;
  --PhoneInputCountryFlag-height: 30px;
}

.phoneNumberCont input {
  background-color: none;
  color: white;
  height: 30px;
  background-color: hsla(0, 0%, 10%, 0);
  border-radius: 0px;
  border: 0px;
  border-bottom: 1px solid hsl(72, 100%, 47%);
  box-shadow: none;

}

.phoneNumberCont input::placeholder {
  color: white;
}

.PhoneInputCountryIconImg {
  
  border-radius: 15px;
  padding: 2px;

}

/*theme colours

dark blue:  #2a4260
mid blue:  #28afe6
mid blue:  #28afe6
background: linear-gradient(to top, #11345f, #288195, white);


*/