.bigDuoButtonCont {
    color: white;
    display: grid;
    width: 100%;
    grid-template-columns: 50% 50%;
    grid-template-rows: 100%;
    background-color: hsla(0, 0%, 13%, 1);
    margin-bottom: 10px;
    padding: 20px 5px;
}

.bigDuoButton {
    padding: 20px 15px;
    background-color: hsla(0, 0%, 10%, 0.9);
    width: calc(100% - 20px);
    border-radius: 15px;
    height: 200px;
    display: flex;
    flex-direction: column;
}

.findDuoHeader {
    position: fixed;
    margin-top: 90px;
    display: flex;
    width: 100%;
    margin-bottom: 0px;
    gap: 10px;
    padding: 10px 20px;
    z-index: 10;
}

.bigDuoButtonIMG {
    height: 59%;
}

.bigDuoButton h5 {
    margin-top: 8px;
    color: rgb(223, 223, 223);
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 1px;
    margin-bottom: 8px;
}

.bigDuoButton p {
    text-align: left;
    height: 70px;
    font-size: 12px;
    margin-top: 5px;
}

.bigDuoButtonInfo {
    width: 30px;
    padding: 7px;
}

.choiceCont {
    color: #ffffff;
    margin-top: 0px;
    padding-bottom: 20px;
    width: 100%;
    position: fixed;
    height: calc(100vh - 45px);
    display: flex;
    flex-direction: column;
    padding: 10px;
    padding-bottom: 59px;
}

.tahCont {
    align-items: center;
    margin-bottom: -5px;
    display: flex;
    flex-direction: column;
    width: 100%;
    letter-spacing: 0;
}

.timeCont {
    color: white;
    display: GRID;
    grid-template-columns: 130px auto;
    border-radius: 15px;
    background-color: hsla(0, 0%, 13%, 1);
    width: 100%;
    margin-bottom: 10px;
    height: 40px;
}

.timeCont label {
    color: hsl(72, 100%, 47%);
    margin-left: 15px;
    font-size: 18px;
    font-weight: 800;
    letter-spacing: 1PX;
    font-style: italic;

}


#time {
    font-family: 'BankGothic Lt BT', sans-serif;
    background-color: transparent;
    border-radius: 4px;
    border: 0px solid hsl(72, 100%, 47%);
    outline: none;
    color: white;
    font-size: 16px;

}

.hintCont {
    color: white;
    display: grid;
    grid-template-columns: 130px auto;
    border-radius: 15px;
    background-color: hsla(0, 0%, 13%, 1);
    padding: 10px;
    width: 100%;
    margin-bottom: 10px;
    height: 50px;

}

.hintCont label {
    color: hsl(72, 100%, 47%);
    margin-left: 7px;
    font-size: 18px;
    font-weight: 800;
    letter-spacing: 1PX;
    font-style: italic;

}

.hint {
    display: flex;
    background-color: transparent;
    border-radius: 0px;
    border: 0px;
    outline: none;
    color: white;
    font-size: 16px;
    width: 100%;
    font-weight: 600;
    font-style: italic;

}

.hint::placeholder {
    color: white;
    font-weight: 100;
    font-size: 14px;
    font-style: normal;


}


.radandconf {
    min-height: 50px;
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 100%;
    margin-top: 5px;
}


.radiusCont {
    display: flex;
    flex-direction: column;
    color: #0e2541;
    background-color: hsla(0, 0%, 13%, 1);
    height: 100%;
    width: calc(100% - 5px);
    border-radius: 15px;
    margin-right: 5px;
    padding: 5px;
}

.radiusCont label {
    font-style: italic;
    color: hsl(72, 100%, 47%);
    letter-spacing: 1px;
    font-weight: 700;

}


.confirmInts {
    width: calc(100% - 5px);
    background-color: hsla(72, 100%, 47%, 50%);
    height: 100%;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
    color: black;
    font-size: 18px;
    font-weight: 900;
    letter-spacing: 1px;
    padding: 0px 25%;
    font-style: italic;

}


.intButtons {
    margin-top: 10px;
    padding: 0 10px;
    height: 100%;
    display: grid;
    gap: 20px;
    row-gap: 30px;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;

}

.intButtons div {
    position: relative;
    padding: 10px;
    border-radius: 15px;
    display: flex;
    background-color: #f5f5f59c;
    aspect-ratio: 1;
    width: 100%;
    justify-content: center;
    align-self: center;

}

.intButtons img {
    width: 80%;
    filter: grayscale(1);
}

.intButtons p {
    font-family: sans-serif;
    letter-spacing: 1px;
    font-weight: 500;
    font-size: 12PX;
    position: absolute;
    top: calc(100% + 5px);
    /* Position the element below its parent container */
}

.intButtons div:active {
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.5);
    top: 5px;
}

.confirmInts:active {
    background-color: hsla(0, 0%, 25%, 80%);
    color: white;
}

.loadMoreByFav {
    background-color: hsla(72, 100%, 47%, 80%);
    color: black;
    align-items: center;
    padding: 3px 15px;
    font-weight: 500;
    font-size: 16px;
    border-radius: 15px;
    margin: 20px auto;
    height: 35px;
    width: 50%;
    display: flex;
    justify-content: center;
    
}