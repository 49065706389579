.rep-choice-page {
  height: calc(100vh );
  display: flex;
  position: fixed;  
  justify-content: center;
  align-items: center;
  width: 100%;
  z-index: 2000;
}

.rep-choice-container {
  width: 85%;
  padding: 20px 20px 10px 20px ;
  border-radius: 15px;
  border: 1px solid black;
  background-color: white;
  height:fit-content;  
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rep-choice-button {
  color: black;
  width: 100%;
  max-width: 400px;
  padding: 8px 20px;
  margin-bottom: 8px;
  background-color: #e1e1e1;
  border: 1px solid black;
  border-radius:36px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.2s;
  font-weight: 500;
}

.rep-choice-button.selected {
  background-color: #ccc;
}

.rep-choice-button:hover {
  background-color: #d4d4d4;
}