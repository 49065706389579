.featuredWorkoutBigCont {
    background-color: hsla(0, 0%, 13%, 1);
    padding: 10px;
    display: grid;
    grid-template-columns: 50% 50%;
}

.featuredWorkoutBigCont img {
    grid-column-start: 1;
    grid-column-end: 2;
    width: 100%;
    filter: invert(.82);
    border-radius: 15px;
    border: 1px solid hsl(257, 100%, 47%);
    margin-top: 0px;
}

.BigWorkOutInfo {
    height: 100%;
    width: 100%;
}

.BigWorkOutInfo h3 {
    text-align: left;
    margin-top: 10px;
    margin-left: 10px;
    color: white;
    font-size: 14px;
    font-style: italic;
}

.WorkoutMidArea {
    background-color: hsla(0, 0%, 13%, 1);
    display: flex;
    width: 100%;
    padding-bottom: 10px;

}

.WorkoutInstructionsCont {
    background-color: hsla(0, 0%, 13%, 1);
    padding: 0px 10px 10px 10px;

}

.WorkoutInstructionsCont h5 {
    color: white;
    text-align: left;
    margin-bottom: 5px;

}

.featuredTip {
    color: white;
    padding: 10px 10px;
    margin-bottom: 10px;
    text-align: left;
    letter-spacing: 1px;
}

.featuredTip div {
    display: flex;
    width: fit-content;
    gap: 20px;
}

.featuredTip img {
    background-color: hsla(0, 0%, 91%, 0.6);
    padding: 5px 10px;
    height: 30px;
    border-radius: 15px;
    filter: invert(1);
}

.featuredTip h4 {
    background-color: hsla(0, 0%, 11%, 0.6);
    padding: 10px 20px;
    width: 150px;
    font-size: 12px;
    border-radius: 15px;
    color: hsl(72, 100%, 47%);
}

.FeaturedCardCont {
    display: flex;
    background-color: hsla(0, 0%, 13%, 1);
    padding: 5px 5px;
    margin-bottom: 10px;
    border-radius: 10px;
}

.smallWorkoutFeatured {
    height: 40px;
    filter: invert(0.82);
    margin-left: 0px;
    border-radius: 5px;
}

.FeaturedCardCont h5 {
    position: absolute;
    color: white;
    margin-left: 50px;
    text-align: left;
}

.smallCardCountCont {
    position: absolute;
    color: white;
    display: flex;
    gap: 10px;
    margin-top: 20px;
    left: 70px;

}

.smallCardCountCont h6 {
    font-size: 20px;
    font-weight: 500;
    color: hsl(72, 100%, 47%);

}

.smallCardCountCont p {
    display: flex;
    background-color: aqua;
    filter: grayscale(1);
    color: black;
    height: 18px;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    width: 70px;
    font-size: 12px;
    font-weight: 500;
}

.deleteFeaturedWorkout {
    height: 20px;
    margin-right: 5px;
}

.confirmFeaturedWorkout {
    position: absolute;
    height: 30px;
    right: 60px;
    margin-top: 5px;
}

.tempTimer {
    position: absolute;
}

.countdown-timer {
    margin-top: 45px;
    padding-top: 30px;
    width: 100%;
    color: hsl(72, 100%, 47%);
    font-size: 35px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}


.countdown-display {
    position: absolute;
    margin-top: -80px;
    width: calc(40%);
    font-size: 50px;
    color: hsl(72, 100%, 47%);
    background-color: rgba(17, 17, 17, 1);
    z-index: 5;
    height: 80px;
}


.progress-container {
    border-radius: 10px;
    margin: 5px 20px 0px 20px;
    width: calc(100% - 40px);
    height: 20px;
    background-color: #ccc;
    position: relative;
}

.progress-bar {
    height: 100%;
    background-color: hsl(72, 100%, 47%);
    border-radius: 10px;
    /* Green color for the progress bar */
    transition: width 0.1s;
    margin-left: 0px;
}

.progress-container p {
    position: fixed;
    width: calc(100% - 40px);
    top: 150px;
    color: hsl(72, 100%, 47%);
    font-size: 20px;
    font-weight: 500;

}

.animatedButtonCont {
    display: flex;
    position: absolute;
    width: 30px;
    right: 20px;
    padding: 5px;
    background-color: hsla(0, 0%, 11%, 0.6);
    margin-top: -5px;
    border-radius: 10px;
}