.minicard {
    border-radius: 15px;
    padding: 5px;
    background-color: hsla(0, 0%, 25%, 80%);
    display: grid;
    grid-template-columns: 50px auto 30px;
    margin-bottom: 10px;
    width: 100%;

}

.mini-user-details {
    text-align: left;
    margin-left: 0;
    line-height: 1.2;
}


.mini-smallText1 {
    margin-left: 10px;
    text-decoration: none;
    color: white;
    display: flex;
    grid-column-start: 2;
    grid-column-end: 3;
    width: 100%;
    font-size: 18px;
    font-weight: 500;
}

.mini-smallText2 {
    margin-left: 10px;
    text-decoration: none;
    color: white;
    display: flex;
    grid-column-start: 2;
    grid-column-end: 3;
    width: 100%;
    font-weight: 400;
}

.mini-user-picture {
    height: 45px;
    width: 45px;
    border-radius: 10px;
    margin: 0;
}

.unblockButton {
    color: white;
    font-size: 12px;
    background-color: rgb(36, 36, 36);
    position: absolute;
    right: 10px;
    margin-top: 15px;
    padding: 5px 8px;
    border-radius: 10px;
    width: 75px;
}

.miniFriendWithCheckBox {
    display: grid;
    width: 100%;
    grid-template-columns: 85% 15%;
    align-items: center;
}

.miniFriendWithCheckBoxAndSearch {
    display: flex;
    position: sticky;
    top: -20px;
    background-color: rgba(15, 15, 15, 0.95);


}

.minicardStatus {
    color: white;
    font-size: 10px;
}

.minicardCheckBox input {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    appearance: none;
    box-shadow: 0 0 0 3px hsla(72, 100%, 47%, 0.5);

    /* Remove default checkbox styling */
    background-color: grey;
    /* Default unselected background */
    border: 2px solid grey;
    /* Default unselected border */
    cursor: pointer;
    transition: background-color 0.3s, border 0.3s;
}

.minicardCheckBox input:checked {
    background-color: hsl(72, 100%, 47%);
    /* Selected color */
    border-color: hsl(72, 100%, 47%);
    /* Change border when selected */
}

.minicardCheckBox input:focus {
    outline: none;
    /* Remove default focus outline */
    /* Optional focus outline */
}