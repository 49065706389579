.Card {
    display: grid;
    position: relative;
    grid-template-columns: 70px 1fr;
    grid-template-rows: 100%;
    margin-bottom: 10px;
    width: calc(100% - 20px);
    height: 80px;
    color: white;
    z-index: 0;
    background-color: hsla(0, 0%, 25%, 0.8);
    border-radius: 15px;
    user-select: none;

}

.Card:active {
    background-color: hsla(0, 0%, 0%, 0.8);
    border-radius: 15px;
}


.cardPP {
    margin-left: 7px;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
    height: 66px;
    border-radius: 10px;
    width: 66px;
    animation: gradientAnimationBackGround 2s infinite linear;

}

.cardInt {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
    height: 35px;
    filter: grayscale(1);
    z-index: 1;
    background-color: aqua;
    border-radius: 10px;
    padding: 5px;
    margin-bottom: 7px;
    margin-left: 10px;

}

.cardName {
    margin-top: 5px;
    margin-left: 10px;
    font-weight: 600;
    color: white;
    font-size: 16px;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;

}

.cardgendandage {
    margin-left: 10px;
    margin-top: 23px;
    position: relative;
    color: white;
    font-size: 12px;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
    font-style: italic;
    font-weight: 600;
    font-size: 10px;
}

.cardTimeCont {
    font-weight: 500;
    font-size: 14px;
    margin-top: 25px;
    color: white;
    margin-right: 10px;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;

}

.cardLocation {
    font-weight: 500;
    font-size: 14px;
    margin-top: 42px;
    color: white;
    margin-right: 10px;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
}

.cardComment {
    font-weight: 500;
    font-size: 14px;
    margin-top: 61px;
    color: white;
    margin-right: 10px;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
}



.interactionCont {
    font-size: 16px;
    box-shadow: 5px 5px 10px 1px black;
    border-radius: 10px;
    margin: 0 10px 0 5px;
    background-color: #2a4260;
    display: flex;
    justify-content: center;
    align-items: center;
}

.repInCard {
    height: 100%;
    display: flex;
    position: absolute;
    left: 123px;
    margin-top: 25px;
    grid-column-start: 1;
    grid-column-end: 3;
    overflow-y: scroll;
    font-size: 14px;
    z-index: 30;
}


.cardLink {
    text-decoration: none;
    border-radius: 10px;
    background-color: #2a4260;
    margin-left: 10px;
    margin-right: 5px;
    height: 100%;
    display: grid;
    grid-template-rows: 15px 30px 25px;
    grid-template-columns: 70px 60px 1fr 1fr;
}

.openMeetingButtonInCard {
    background-color: aqua;
    position: absolute;
    left: 160px;
    bottom: 8px;
    padding: 5px;
    border-radius: 15px;
    height: 30px;
    filter: grayscale(1);
}