.tempMeetingPage {
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -45px;
    width: 100%;
    height: 100vh;
    z-index: 500;
}

.meetingPageCont {
    position: fixed;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 1fr auto 1fr;
    top: 0;
    background-color: rgba(17, 17, 17, 0.95);
    margin: auto;
    width: calc(100%);
    height: 100%;
    color: white;
}

.fuLLwidthandHeight {
    width: 100%;
    height: 100%;
}

.meetingCardCont {
    background-color: hsla(0, 0%, 13%, 1);
    padding: 10px 0px;
    margin-bottom: 10px;
    border-radius: 10px;
}