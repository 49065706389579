.MutualFriends {
    color: hsl(72, 100%, 47%);
}

.mutualPage {
    display: flex;
    position: fixed;
    width: 100%;
    height: 100%;
    padding: 30px 0px;
    padding-bottom: 70px;
    flex-direction: column;
    left: 0;
    top: 0;
    justify-content: center;
    align-items: center;
    background-color: rgba(15, 15, 15, 0.95);
    z-index: 50;

}

.mutuallist {
    position: relative;
    width: calc(100% - 30px);
    flex-wrap: wrap;
    overflow: auto;
    z-index: 0;
    margin-top: 20px;
    -ms-overflow-style: none;
    scrollbar-width: none;
}



.mutuallist::-webkit-scrollbar {
    display: none;
}

.mutuallist h2 {
    color: hsla(72, 100%, 47%, 65%);
    letter-spacing: 1px;
    font-style: italic;
}

.miniSerchInput {
    font-size: 16px;
    width: 130px;
    border: 0px;
    border-radius: 0px;
    border-bottom: 1px solid hsl(72, 100%, 47%);
    line-height: 1.2;
    color: hsla(72, 100%, 47%, 65%);
    font-weight: 500;
    background-color: rgba(15, 15, 15, 0.95);
}

.miniSerchInput::placeholder {
    background-color: rgba(15, 15, 15, 0.95);
    font-size: 16px;
    letter-spacing: 1px;
    font-weight: 400;
    color: hsla(72, 100%, 47%, 65%);
}