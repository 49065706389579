.allHome {
    width: 100%;
}

.mainHome {
    width: 100%;
    display: grid;
    grid-template-columns: 100%;
    justify-content: center;
    position: relative;
    top: 0px;
    z-index: 5;

}

.main {
    width: 100%;
    justify-content: center;

}

.homeTrans {
    width: 100%;
    padding: 0;
    position: relative;
    display: grid;
    grid-template-columns: 100%;

}


.profileBreak {
    background-color: #2a4260;
    position: fixed;
    top: 225px;
    height: 35px;
    width: 100%;
    border-top: 1px solid white;
    z-index: 0;

}

.disclaimer2 {
    font-weight: 500;
    font-size: 16px;
    color: black;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
}

.gender-options {
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

.gender-option {
    display: inline-block;
    width: 100px;
    padding: 10px 20px;
    background-color: #d3d3d3;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;


}

.gender-option.active {
    background-color: hsla(72, 100%, 47%, 65%);
    color: black;
}

.confirmWelcomePage {
    font-size: 18px;
    font-weight: 900;
    margin-top: 10px;
    background-color: #8585858c;
    padding: 18px 0px;
    width: 200px;
    border-radius: 15px;
}

.swipeBack {
    bottom: 50px;
    left: 0;
    position: fixed;
    height: calc(100vh - 45px);
    width: 30px;
    z-index: 10;
}

.swipeForward {
    bottom: 250px;
    right: 0;
    position: fixed;
    height: calc(100vh - 250px);
    width: 30px;
    z-index: 10;
}