.bigCardContInAdmin {
    background-color: hsla(0, 0%, 25%, .8);
    display: grid;
    grid-template-columns: 30% 70% ;
    grid-template-rows: 20% 10% 20% 25% 25%;    
    border-radius: 15px;
    margin: auto;
    width: calc(100%);
    padding: 20px;
    color: white;
    margin-bottom: 10px;
}



.bigCardPic {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    width: 100%;
    border-radius: 15px;
    
}

.bigCardName {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
    letter-spacing: 2px;
    font-weight: 700;
    width: calc(100%);
    font-size: 16px;

}

.bigCardgendandage {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;
    display: flex;
    width: fit-content;
    font-weight: 500;
    gap: 10px;
    font-style: italic;
    font-weight: 600;
    font-size: 14px;
}

.bigCardBio {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 4;
    grid-row-end: 5;
    font-style: italic;
    font-weight: 600;
}

.bigCardFavs {
    display: flex;
    width: fit-content;
    gap: 10px;
    height: 40;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 3;
    grid-row-end: 4;
}

.bigCardFavs p {
    font-weight: 600;
}

.bigCardFav {
    filter: grayscale(1);
    background-color: whitesmoke;
    height: 35px;
    width: 35px;
    padding: 5px;
    border-radius: 10px;
}

.proFrBt {    
    z-index: 2;
    margin-top: 10px;
}

.currentPlanBigCard {
    display: flex;
    flex-wrap: wrap;
    font-weight: 500;
    width: 100%;
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 5;
    grid-row-end: 6;
}

.reps {
    position: absolute;
    display: flex;
    width: 40px;
    height: 40px;
    background-color: #00000070;
    border-radius: 15px;
    z-index: 10;
    right: 30px;
    margin-top: 30px;
    color: white;
}

.ban {
    position: absolute;
    display: flex;
    width: 40px;
    height: 40px;
    background-color: red;
    border-radius: 15px;
    z-index: 10;
    right: 30px;
    margin-top: 80px;
    color: white;
}

.repsContent {
    background-color: whitesmoke;
    color: black;
    position: fixed;
    width: calc(100% - 40px);
    left: 20px;
    line-height: 1.5;
    text-align: left;
    top: 30%;
}


.bigCardBackArrow {
    position: absolute;
    height: 25px;
    z-index: 2;
    right: 30px;
    margin-top: -10px;
}

.otherBigPic {
    display: flex;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.8);
    width: 100%;
    left: 0;
    top: 0;
    height: calc(100vh);
    z-index: 9999;
}

.otherBigPic img {
    margin: auto auto;
    width: 90%;
    max-width: 330px;
    border-radius: 15px;
}
