.openShareCont {
    justify-self: center;
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    left: 0px;
    top: 0px;
    z-index: 20;
}

.shareHelper {
    position: fixed;
    height: 45px;
    width: 40px;
    top: 0px;
    left: 92px
}

.openShareBanner {
    position: fixed;
    height: 24px;
    top: 10px;
    left: 94px;
    z-index: 8000;

}

.shareCloseButton {
    position: absolute;
    height: 45px;
    padding:10px;
    z-index: 2;
    right: 0px;
    top: 0px;
}

.openShareBanner:active {
    background-color: hsla(0, 0%, 25%, 0.95);
}

.ShareBanner {
    position: relative;
    background-color: rgba(0, 0, 0, 0.85);
    width: calc(100% - 30px);
    min-width: 320;
    margin: auto auto;
    border-radius: 15px;
    padding: 15px 10px;
}

.bigShare {
    margin-bottom: 8px;
    color: hsl(72, 100%, 47%);;
}

.shareMotivate {
    margin-bottom: 10px;
    font-weight: 500;
    line-height: 1.2;
}

.severalShareResponse {
    margin: 10px 10px 0px 10px;
    display: flex;
    gap: 20px;

}