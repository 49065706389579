.myPageCont {
    padding: 0px 0px;
    background-color: rgba(23, 23, 23, 0.906);
}


.ProfileHeader2 {
    position: sticky;
    background-color: hsla(0, 0%, 13%, 1);
    padding: 10px 0px 45px 0px;
    margin-bottom: 10px;
    top: -65px;
    border-bottom: 1px solid black;
    z-index: 20;
}

.MiniCreateButtonsCont {
    width: fit-content;
    gap: 10px;
    display: flex;

}


.miniPP {
    height: 50px;
    width: 50px;
    border-radius: 15px;
    animation: gradientAnimationBackGround .5s infinite linear;
}


.openChangePP {
    background-color: #f5f5f59c;
    height: 50px;
    padding: 5px;
    border-radius: 15px;
}

.openChangePP:active {
 filter: invert(1);
}

.goToCreatPost {
    display: flex;
    background-color: #f5f5f59c;
    color: black;
    height: 50px;
    width: 110px;
    border-radius: 15px;
    padding: 0px 15px;
    align-items: center;
}

.goToCreatPost:active {
 filter: invert(1);
}

.postsFilterCont {
    text-align: center;
    display: flex;
    position: absolute;
    height: 20px;
    width: 100%;
    left: 0;
    bottom: 0;
    margin-bottom: 10px;
}

.postsFilterCont p {
    color: black;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f5f5f59c;
    width: 25%;
    margin: auto;
    border-radius: 10px ;
    font-size: 13px;

}


.changeProfilePictureCont img {
    width: 100%;
}

.MiniCreateButtonsCont p {
    color: white;
    font-weight: 500;
}


/*
background-color: hsla(0, 0%, 25%, 0.951);
*/