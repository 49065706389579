.customWorkoutSetupCard {
    width: 100%;
    display: grid;
    grid-template-columns: 100px auto;
    border-radius: 20px;
    height: 40px;
    margin-bottom: 10px;
}

.CusotmWorkoutSetupName {
    color: white;
    margin-left: 20px;
    font-size: 16px;
    font-weight: 900;
    letter-spacing: 1px;
    font-style: italic;
}

.customWorkoutSetupCard div {
    background-color: hsl(72, 100%, 47%);
    width: 90px;
    display: flex;
    color: black;
    height: 30px;
    border-radius: 10px;
    padding-right: 10px;
    margin-right: 20px;
}

.customWorkoutSetupCard {
    font-weight: 500;
}

.customWorkoutSetupCard input {
    background-color: rgba(192, 240, 0, 0);
    border-radius: 15px;
    border: 0px;
    width: 50px;
    font-size: large;
    color: black;
    font-weight: 500;
}

.WorkoutNamePlaceHolder {
    position: absolute;
    font-size: 16px;
    height: 30px;
    right: 20px;
    border-radius: 10px;
    width: 150px;

}

.WorkoutNamePlaceHolder::placeholder {
    font-size: medium;
    display: flex;
    text-align: center;
}



.goToLogged {
    z-index: 950;
    top: 535px;
    width: 100%;
    display: flex;

}

.goToLogged p {
    background-color: hsla(0, 0%, 10%, 0.9);
    padding: 15px 20px;
    font-size: 12px;
    border-radius: 15px;
    font-weight: 800;
    letter-spacing: 1px;
    color: hsl(72, 100%, 47%);
}

.noPrevWorkout {
    color: white;
    margin-top: 20px;

}

.noPrevWorkout p {
    font-size: 18px;
    margin-bottom: 10px;
}

.noPrevWorkout img {
    width: 100px;
    padding: 10px;
    background-color: rgb(41, 41, 41);
    border-radius: 15px;
}