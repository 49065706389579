.notibutton {
    position: fixed;
    top: 0px;
    height: 45px;
    width: 30px;
    right: 15px;
    align-items: center;
    display: flex;
}

.notibutton img {
    width: 20px;
}

.notibutton img:active {
    filter: grayscale(1);
}

.notList {
    grid-column-start: 5;
    grid-column-end: 6;
    display: flex;
}

.fullScreenEvent {
    background-color: rgba(0, 0, 0, 0.936);
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0px;
    left: 0px;
    padding-top: 80px;
}