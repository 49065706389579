.backArrow2 {
    position: relative;
    width: 18px;
    left: -7px;


}

.onChatCont {
    width: 100%;
    display: flex;
    position: absolute;
    bottom: 50px;
    flex-direction: column;
    height: 100%;
    overflow: auto;
}

.cardHelper {
    position: fixed;
    margin-top: -10px;
    top: 65px;
    grid-row-start: 1;
    grid-row-end: 2;
    width: 100%;
    height: 80px;
    border-radius: 10px;
    z-index: 3;

}

.cardHelper2 {
    position: absolute;
    margin-top: 0px;
    top: 0px;
    grid-row-start: 1;
    grid-row-end: 2;
    width: calc(100% - 20px);
    height: 40px;
    border-radius: 10px;
    background-color: transparent;
    z-index: 3;
    justify-content: center;
    align-items: center;
    display: flex;
    color: black;
    font-weight: 500;
    font-size: 13px;
}



.userProfile {
    width: 100%;
    position: fixed;
    top: 45px
}

.repButton2 {
    background-color: white;
    height: 40px;
    border-radius: 5px;
    z-index: 30;

}

.repButton {
    filter: invert(1);
    height: 23px;
    border-radius: 5px;
    z-index: 30;
}

.reporting {
    background-color: red;
}

.userPostsCont {
    position: relative;
    width: 100%;
    top: 0px;
    left: 0px;
    height: calc(100%);
    background-color: rgba(23, 23, 23, 0.906);
}

.Didntpostyet {
    background-color: rgba(23, 23, 23, 0.906);
    width: 100%;
    padding: 20px 10px;
    line-height: 1.2;
    margin: auto auto;
    align-self: center;

}
