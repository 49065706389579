.otherProfileCont {
    background-color: hsla(0, 0%, 25%, .8);
    border-radius: 15px;
    margin: auto;
    width: calc(100% - 40px);
    padding: 20px;
    color: white;
}

.otherProfilePic {
    width: 60%;
    aspect-ratio: 1;
    border-radius: 15px;
    margin-bottom: 10px;
    animation: gradientAnimationBackGround 2s infinite linear;
}

.otherProfileName {
    margin-bottom: 10px;
    letter-spacing: 2px;
    font-weight: 900;
    width: calc(100% - 40px);
}

.otherProfilegendandage {
    margin: -5px auto;
    display: flex;
    width: fit-content;
    font-weight: 500;
    gap: 10px;
    font-style: italic;
    font-weight: 600;
    font-size: 14px;

}

.otherProfileBio {
    margin: 10px auto;
    text-align: left;
    font-size: 14px;
    font-style: italic;
    font-weight: 600;
}

.otherFavs {
    display: flex;
    width: fit-content;
    gap: 10px;
    height: 40;
}

.otherFavs p {
    font-weight: 600;
}

.otherProfileFav {
    filter: grayscale(1);
    background-color: whitesmoke;
    height: 35px;
    width: 35px;
    padding: 5px;
    border-radius: 10px;
}

.proFrBt {
    z-index: 2;
    margin-top: 10px;
}

.currentPlanOther {
    display: flex;
    flex-wrap: wrap;
    font-weight: 500;
}

.repInProf {
    position: absolute;
    width: 40px;
    height: 40px;
    background-color: #00000070;
    padding-top: 8px;
    border-radius: 15px;
    z-index: 10;
    left: 30px;
    margin-top: 30px;
}

.swipeBackInUser {
    bottom: 0;
    left: 0;
    position: fixed;
    height: calc(100vh - 45px);
    width: 40px;
    z-index: 550;
}

.topBadgesProfileCont {
    border-radius: 15px;
    padding: 10px 0px;
    width: 50px;
    position: absolute;
    margin-top: 30px;
    right: 9%;
    display: flex;
    flex-direction: column;
    gap: 0px;
}

.topBadgeItemCont {
    padding-bottom: 3px;
    border-radius: 15px;
}

.topBadgeItemCont img {
    width: 35px;
}

.otherProfileBackArrow {
    position: absolute;
    height: 25px;
    z-index: 2;
    right: 30px;
    margin-top: -10px;
}

.otherBigPic {
    display: flex;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.8);
    width: 100%;
    left: 0;
    top: 0;
    height: calc(100vh);
    z-index: 9999;
}

.otherBigPic img {
    width: 90%;
    max-width: 330px;
    border-radius: 15px;
}