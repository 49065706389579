/* LeadBoardCard.css */
.buttonWithImageVert {
    display: grid;
    grid-template-columns: auto;
    height: 40px;
    width: 80%;
    background-color: hsla(0, 0%, 10%, 0.9);
    justify-content: space-evenly;
    border-radius: 15px;
    align-items: center;
}

.buttonWithImageVert img {
    height: 25px;
}

.buttonWithImageVert h5 {
    font-size: 8px;
}

.ranking-header p {
    background-color: hsla(0, 0%, 13%, 1);
    color: hsl(72, 100%, 47%);
    width: 100%;
    padding-left: 10px;
    text-align: left;
    font-size: 34px;
    margin-bottom: 0px;
    height: 35px;
   
}

.leadBoardCardCont {
    background-color: hsla(0, 0%, 13%, 1);
    padding: 5px 5px;
    margin-bottom: 10px;
    border-radius: 10px;
    display: flex;
    height: 70px;
    position: relative;
}

.userImage {
    width: 60px;
    /* Adjust image size */
    height: 60px;
    /* Adjust image size */
    border-radius: 10px;
    /* Make the image round */
    margin-right: 10px;
}

.userInfo {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    flex-grow: 1;
    /* Takes up remaining space */
    height: 100%;
}

.userName {
    text-align: left;
    margin-left: 0px;
    font-size: 18px;
    margin-top: 0px;
    /* Adjust font size */
    font-weight: bold;
    color: #ffffff;
    /* White text */
}

.rankText {
    color: hsl(72, 100%, 47%);
    margin-top: 0px;
    font-weight: bold;

}

.countSpan {
    color: hsl(72, 100%, 47%);
    font-size: 24px;
    font-weight: bold;
}


.confirmedText {
    color: #ffffff;
    margin-left: 0px;
    /* White text */
    font-size: 16px;

    /* Adjust font size */
}