/* Container styling */
.descContPage {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: hsla(0, 0%, 10%, 0.9);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
}

.refPrompCont {
    background: hsla(0, 0%, 13%, 1);
    padding: 30px;
    border-radius: 15px;
    text-align: center;
    max-width: 350px;
    box-shadow: 0 30px 12px rgba(0, 0, 0, 0.3);
    animation: fadeIn 0.5s ease-in-out;
    color: #fff;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10%);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Header styling */
.promptHeader {
    font-size: 22px;
    color: hsl(72, 100%, 47%);
    margin-bottom: 15px;
    font-weight: bold;
}

/* Subscription details styling */
.subscriptionDetails {
    font-size: 18px;
    margin-bottom: 25px;
}

/* Button container */
.subscriptionButtons {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

/* Button hierarchy */
.primaryButton {
    background-color: hsl(72, 100%, 47%);
    color: black;
    border: none;
    border-radius: 10px;
    padding: 12px;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.1s;
    margin-bottom: 5px;
}

.primaryButton:hover {
    background-color: hsla(72, 100%, 37%, 50%);
}

.secondaryButton {
    background-color: hsla(0, 0%, 15%, 0.9);
    color: rgb(255, 104, 104);
    border: 1px solid rgb(255, 104, 104);
    border-radius: 10px;
    padding: 12px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.secondaryButton:hover {
    background-color: hsla(0, 0%, 15%, 0.7);
    color: #fff;
}

.tertiaryButton {
    background: none;
    color: hsla(72, 100%, 47%);
    border: none;
    padding: 8px;
    font-size: 14px;
    cursor: pointer;
    text-decoration: underline;
}

.tertiaryButton:hover {
    color: hsla(72, 100%, 37%);
}

/* Free option styling */
.freeOption {
    margin-top: 10px;
    font-size: 14px;
    color: hsl(72, 100%, 47%);
}

.learnMoreLink {
    color: hsl(72, 100%, 47%);
    cursor: pointer;
    font-weight: bold;
    text-decoration: underline;
}

.learnMoreLink:hover {
    color: hsla(72, 100%, 37%);
}