.chatNotibutton {
    position: fixed;
    top: 0px;
    height: 45px;
    right: 45px;
    width: 35px;
    align-items: center;
    display: flex;

}

.chatNotibutton img {
    width: 25px;
    margin: auto;
}

.chatNotibutton img:active {
    filter: grayscale(1);
}

.notList {
    grid-column-start: 2;
    grid-column-end: 6;
    display: flex;
    z-index: 50;
}

.reqs {
    background-color: rgba(0, 0, 0, 0.914);
    border: 1px solid hsla(72, 100%, 47%, 65%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    border-radius: 12px;
    width: 260px;
    min-height: 40px;
    max-height: calc(100vh - 100px);
    top: 35px;
    right: 10px;
    overflow-y: scroll;
    overflow-x: hidden;
    z-index: 50;
    padding: 3px 3px;
    gap: 3px;
    color: white;
}

.smallCard {
    width: 100%;
    display: grid;
    grid-template-columns: 40px auto 30px;
    border-radius: 10px;
    padding: 3px;
    background-color: hsla(0, 0%, 25%, 0.95);
}

.smallChatReq {
    border-radius: 10px;
    padding: 3px;
    background-color: white;
    display: grid;
    grid-template-columns: 35px auto 30px;
    background-color: hsla(0, 0%, 25%, 0.95);
}

.smallLink {
    grid-column-start: 1;
    grid-column-end: 2;
    display: flex;
    align-items: center;
    position: relative;
    left: 0;
    border-radius: 10px;
    margin: 0;
    color: white;
}

.smallLink p {
    color: white;
    text-align: left;
    margin-left: 0px;
}

.closeX {
    filter: invert(1);
}

.smallProfPic {
    height: 35px;
    border-radius: 10px;
    margin: 0;
}

.smallDBoutns {
    grid-column-start: 3;
    grid-column-end: 4;
    font-size: 12px;
    margin: 0;
    border-radius: 10px;
    background-color: #2a4260;
    display: flex;
    justify-content: center;
    align-items: center;
}

.smallText {
    margin-left: 3px;
    text-decoration: none;
    color: black;
    display: flex;
    grid-column-start: 2;
    grid-column-end: 3;
    width: 100%;
    color: white;
}

.smallText p {
    font-size: 14px;
    margin-left: 0px;
    text-align: left;

}

.goToChat {
    background-color: hsla(0, 0%, 25%, 0.95);
    width: 100%;
    border-radius: 10px;
    padding: 4px;
    font-size: 14px;
    font-weight: 500;
}