.CoinCont {
    display: flex;
    gap: 6px;
    justify-content: center;
    align-items: center;
    background-color: black;
    padding: 5px 10px;
    border-radius: 15px;
}

.CoinCont p {
    font-family: sans-serif;
    color: hsl(72, 100%, 47%);
}


.fitCoin {
    width: 20px;
    padding: 1px;
    background-color: hsl(72, 100%, 47%);
    border-radius: 50%;
}

.dailyCont {
    background-color: hsla(0, 0%, 13%, 1);
    padding: 14px 0px;
    margin-bottom: 10px;
}

.dailyCont h4 {
    padding-left: 10px;
    text-align: left;
    color: white;
    width: 100%;
    font-size: 16px;
}

.dailyCont h4 span {
    margin-left: 30px;
    text-align: left;
    color: hsl(72, 100%, 47%);
    width: 100%;
    font-size: 16px;
    height: 30px;
}

.dailyObjective {
    padding-top: 15px;
    padding-left: 10px;
    padding-right: 10px;
}

.dailyCard {
    width: 100%;
    display: grid;
    grid-template-columns: auto auto;
    border-radius: 20px;
    height: 42px;
    background-color: hsla(0, 0%, 10%, 0.9);
    margin-bottom: 10px;
}

.dropDownArrow {
    height: 25px;
    align-self: center;
    right: 30px;
    transform: rotate(270deg);
    transition: .2s linear;
}

.closeDropDownArrow {
    height: 25px;
    right: 30px;
    transform: rotate(180deg);
    transition: .2s linear;

}

.objectiveName {
    color: white;
    font-weight: 500;
    margin-left: 25px;
}

.dailyCard div {
    position: absolute;
    right: 15px;
    margin-top: 6px;
}

.fullScreenCleanBox {
    margin: 45px auto;
    width: 80%;
    aspect-ratio: 1;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.95);
    display: flex;
    flex-direction: column;
    border-radius: 25px;
    text-align: center;
    border: 1px solid hsla(72, 100%, 47%, 30%);
    ;
}

.achievementImgHolder {
    margin: 10px;
    height: 70%;
}

.achievementImgHolder img {
    margin: 0px auto;
    height: 100%;
}

.fullScreenCleanBox h5 {
    font-family: sans-serif;
    margin-top: 0px;
    margin-bottom: 0px;
    color: white;
    font-weight: 400;
}

.fullScreenCleanBox p {
    font-weight: 500;
    margin-top: 10px;
    color: white;
}

.myBadgesTitle {
    display: grid;
    grid-template-columns: 120px auto;
}

.topAchivmentsCont {
    display: flex;
    width: fit-content;
    margin-left: 10px;
    gap: 25px;
}

.badgeCard {
    width: 100%;
    display: grid;
    grid-template-columns: 100px auto;
    border-radius: 20px;
    height: 40px;
    background-color: hsla(0, 0%, 10%, 0.9);
    margin-bottom: 10px;
}

.achievement {
    display: flex;
    width: 25px;
    height: 25px;
}



.achievement img {
    display: flex;
    width: 25px;
    height: 100%;
}

.achievements-container {
    display: flex;
    gap: 20px;

}