.chat-container {
  width: 100%;
  height: calc(100%);
  margin-top: 200px;
  overflow: hidden;
  position: relative;
}

.chat-box {
  position: fixed;
  display: grid;
  width: 100%;
  flex-wrap: wrap;
  padding: 0px 10px;
  border-radius: 4px;
  overflow: auto;
  z-index: 0;

}


.chatCompon {
  display: grid;
  bottom: 0;
  grid-template-columns: 100%;
  width: 100%;
  overflow: scroll;
  z-index: 1;
}

.editChatCont {
  background-color: hsla(0, 0%, 10%, 0.9);
  border-radius: 15px;
  width: 20%;
  height: 30px;
  position: fixed;
  right: 10px;
  z-index: 5;
}

.editChatCont img {
  height: 100%;
  padding: 5px;
}

.boxchatwarn {
  line-height: 1.1;
  margin: auto;
  width: 80%;
  padding: 10px;
  background-color: #f5f5f5e8;
  border-radius: 8px;
  text-align: center;
  border: 1px solid #000000;
  margin-bottom: 20px;

}


.boxchatwarn h3 {
  color: hsl(72, 100%, 28%);
  font-size: 20px;
  margin-bottom: 3px;
}

.boxchatwarn h5 {
  color: #000000;
  font-size: 13px;
  margin-bottom: 5px;
}

.boxchatwarn p {
  color: #000000;
  font-size: 12px;
  margin-bottom: 3px;
  text-align: left;
}


.message {
  text-align: left !important;
  max-width: 80%;
  margin-bottom: 10px;
  border-radius: 15px;
  word-break: break-word;
  /* Ensures long words break to the next line */
  overflow-wrap: break-word;
  /* Alternative property to ensure long words break */
}

.sent {
  margin-right: 10px;
  align-self: flex-end;
  background-color: hsla(0, 0%, 25%, 0.8);
  color: white;
  font-weight: 500;
}

.received {
  margin-left: 10px;
  align-self: flex-start;
  background-color: #f1f1f1;
  color: #333333;
  font-weight: 500;
}

.message-content {
  text-align: left !important;
  display: flex;
  flex-direction: column;
  gap: 0px;
}

.input-container {
  background-color: hsla(0, 0%, 25%, 80%);
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  position: fixed;
  bottom: 0px;
  display: flex;
  align-items: center;
  padding: 6px 15px;
  gap: 5px;
  min-height: 50px;
}

.input-container textarea {
  flex: 1;
  padding-top: 5px;
  padding-left: 8px;
  display: flex;
  color: black;
  font-size: 16px;
  font-weight: 500;
  border-radius: 15px;
  border: 1px solid #cccccc;
  min-height: 35px;
  max-height: 150px;
  white-space: pre-wrap;
  text-align: left;
}

.input-container textarea::placeholder {
  text-align: center;
}

.input-container button {
  padding: 8px 16px;
  margin-left: 10px;
  background-color: #2980b9;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.chatButtons {
  height: 32px;
  padding: 3px;
}

.sendTextMsgButtonCont {
  display: flex;
}

.sendTextMsgButtonHelper {
  position: absolute;
  height: 70px;
  width: 70px;
  bottom: 0px;
  right: 0px;
}

.input-container button:hover {
  background-color: #1c638e;
}

.input-container button:active {
  background-color: #000000;
}

.uploadingAudio {
  color: white;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: white;
  width: 100%;
}

.audioChat {
  height: 20px;
  width: 240px;
}

.audioChatSent {
  height: 20px;
  background-color: none;


}

.audioChatReceived {
  height: 20px !important;
  filter: invert(100%);
  background-color: hsl(0, 0%, 5%);
}

/* Media controls panel */
.audioChatSent::-webkit-media-controls-panel {
  padding: 0px;
  background-color: hsla(0, 0%, 10%, 0.9);
}

/* Mute button */
.audioChatSent::-webkit-media-controls-mute-button {
  filter: invert(100%) !important;
  padding: 0px;
  margin: 0px;
}

/* Play button */
.audioChatSent::-webkit-media-controls-play-button {
  filter: invert(100%) !important;

}

/* Current time display */
.audioChatSent::-webkit-media-controls-current-time-display {
  color: white;
  font-size: 10px;
  padding: 0px;
  margin: 0px;
}

/* Time remaining display */
.audioChatSent::-webkit-media-controls-time-remaining-display {
  color: white;
  font-size: 10px;
  padding: 0px;
  margin: 0px;
}

/* Timeline */
.audioChatSent::-webkit-media-controls-timeline {
  filter: invert(100%) !important;
  padding: 0px;
  margin: 0px;
  padding-left: 10px;
}


.audioChatReceived::-webkit-media-controls-panel {
  padding: 0px;
}

/* Mute button */
.audioChatReceived::-webkit-media-controls-mute-button {
  padding: 0px;
  margin: 0px;
}

/* Current time display */
.audioChatReceived::-webkit-media-controls-current-time-display {
  font-size: 10px;
  padding: 0px;
  margin: 0px;
}

/* Time remaining display */
.audioChatReceived::-webkit-media-controls-time-remaining-display {
  font-size: 10px;
  padding: 0px;
  margin: 0px;
}

/* Timeline */
.audioChatReceived::-webkit-media-controls-timeline {
  padding: 0px;
  margin: 0px;
  padding-left: 10px;
}

.audioChatReceived::-webkit-media-controls-enclosure {
  filter: invert(100%);
}

.picHandlerCont {
  display: flex;
  position: fixed;
  flex-direction: column;
  width: 100%;
  bottom: 0px;
  left: 0px;
  background-color: black;
  padding: 10px 0px;

}

.prevPicChat {
  width: 80%;
  aspect-ratio: auto;
  margin-bottom: 10px;
}

.picButtonsCont {
  display: flex;
  width: 50%;

}

.imageChat {
  width: 200px;
  display: flex;
  min-height: 200px;
}

.imageChat img {
  width: 100%;
  border-radius: 10px;
}

.imageChat video {
  width: 100%;
  border-radius: 10px;
}

.onChatCard {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 20px);
  height: 55px;
  padding: 0 5px;
  border-radius: 25px;
  background-color: hsla(0, 0%, 25%, 80%);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  position: relative;
}

.user-picture {
  height: 45px;
  width: 45px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 0px;
}

.info {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.name-status {
  display: flex;
  align-items: center;
  color: white;
  gap: 5px;
  margin-left: 0px;
  position: relative;
  padding-top: 3px;
}

.user-name {
  font-size: 16px;
  font-weight: 500;
  margin-right: 10px;

}

.loading-icon {
  width: 10px;
  height: 10px;
  margin-left: 5px;
}

.status-indicator {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-left: 5px;
}

.status-indicator.online {
  background-color: hsl(72, 100%, 47%);
}

.status-indicator.offline {
  background-color: rgba(255, 0, 0, 0.483);
}

.report-icon {
  height: 30px;
  width: 30px;
  padding: 5px;
  cursor: pointer;
  filter: invert(1);
  margin-right: 8px;
}




/* Container for the typing indicator */
.typing-indicator {
  display: flex;
  align-items: center;
  font-size: 12px;
  /* Font size as requested */
  color: #d0d0d0;
  width: 100%;
  /* Default text color */
}

/* Animation for typing dots */
.typing-dots {
  display: flex;
  align-items: center;
  margin-left: 5px;
  padding-bottom: 3px;
  text-align: left;
  width: 100%;
  letter-spacing: 1px;
}

.typing-dots .dot {
  width: 4px;
  height: 4px;
  margin: 0 2px;
  background-color: #999;
  border-radius: 50%;
  animation: bounce 1.4s infinite;
}

.typing-dots .dot:nth-child(2) {
  animation-delay: 0.2s;
}

.typing-dots .dot:nth-child(3) {
  animation-delay: 0.4s;
}

/* Keyframes for bouncing animation */
@keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-10px);
  }

  60% {
    transform: translateY(-5px);
  }
}