.bottomNavCont {
    border-top: 1px solid hsla(72, 100%, 47%, 40%);
    background-color: black;
    position: fixed;
    width: 100%;
    display: grid;
    padding: 2px 5px;
    height: 59px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 100%;
    gap: 10px;
    bottom: 0px;  
    padding: 1px 10px;    
    transition: all 0.2s;  
    -webkit-transition: all 0.2s;

}

.bottomNavButton {
    background-color: black;
    padding: 10px 0px;
    color: white;
    width: 100%;
    border-Radius: 15px;
    height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

.bottomNavButton img {
    height: 25px;
    min-height: 25px;
    top:5px;
    position: absolute;
}

.bottomNavButton p {
    font-weight: 500;
    color: hsl(72, 100%, 47%);
    font-size: 10px;
    bottom: 3px;
    position: absolute;
}

.activeGrey:active {
    background-color: hsla(0, 0%, 53%, 0.6);
    border-radius: 20px;
    color: black;
    filter: none;
}

.activeWhite:active {
    background-color: black;
    border-radius: 20px;
    color: white;
    filter: none;
}